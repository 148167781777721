import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Button, Typography, Stack, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  type ColumnDefinition,
  Modal,
  ModalOpenButton,
  RefreshButton,
  useRequestSnackbar
} from '@r40cap/ui'
import {
  type Liability,
  type Loan,
  type TransactionCreation,
  blotterApi
} from '@r40cap/pms-sdk'

import { loanDetailsLiabilityColumns } from './constants'
import type { BlotterBigModal } from './types'
import type { BlotterInputType, LiabilityRow } from '../common/types'
import SmallLegTable from './SmallLegTable'
import LoanPanel from './panels/LoanPanel'
import { AddTransactionToLoanModal } from './modals/AddTransactionToLoanModal'

function LoanDetails (): React.JSX.Element {
  const { loanId } = useParams()
  const navigate = useNavigate()
  const { palette } = useTheme()
  const { showSnackbar } = useRequestSnackbar()
  const {
    data: liabilitiesData,
    isFetching: isLiabilitiesFetching,
    refetch: liabilitiesRefetch
  } = blotterApi.useGetLoanLiabilitiesQuery({
    loanId: loanId ?? ''
  })
  const {
    data: loanData,
    isFetching: isLoanFetching,
    refetch: loanRefetch
  } = blotterApi.useGetLoanByIdQuery({
    loanId: loanId ?? ''
  })
  const [postTransaction] = blotterApi.useAddTransactionsMutation()

  const [loan, setLoan] = useState<Loan | null>(null)

  useEffect(() => {
    setLoanResetSignal(!loanResetSignal)
    if (loanData !== undefined && loanData !== null) {
      setLoan(loanData.data)
    } else {
      setLoan(null)
    }
  }, [loanData])

  const [editLiabilities] = blotterApi.useEditLiabilitiesMutation()
  const [liabilitiesSetDataSignal, setLiabilitiesSetDataSignal] = useState<boolean>(false)
  const [loanResetSignal, setLoanResetSignal] = useState<boolean>(false)
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false)

  function addTransactionToLoan (transaction: TransactionCreation): void {
    if (loan !== null) {
      showSnackbar({
        isOpen: true,
        message: 'Pushing Transaction',
        status: 'processing'
      })
      postTransaction({
        additions: [transaction]
      })
        .then((value) => {
          showSnackbar({
            isOpen: true,
            message: 'Done',
            status: 'success'
          })
          setAddModalOpen(false)
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to add Transaction',
            status: 'error'
          })
        })
    }
  }

  function refresh (): void {
    refreshLiabilitiesFunction()
    refreshLoanFunction()
  }

  function refreshLiabilitiesFunction (): void {
    liabilitiesRefetch().then(() => {
      setLiabilitiesSetDataSignal(!liabilitiesSetDataSignal)
    }).catch(() => {
      console.error('Error Refreshing Liabilities')
    })
  }

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/blotter/loans')
    }
  }

  function pushLiabilities (additions: Liability[], edits: Liability[]): void {
    if (edits.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Editing Liabilities',
        status: 'processing'
      })
      editLiabilities({ edits })
        .then((value) => {
          showSnackbar({
            isOpen: true,
            message: 'Done',
            status: 'processing'
          })
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to edit Liabilities',
            status: 'error'
          })
        })
    }
  }

  function refreshLoanFunction (): void {
    loanRefetch().then(() => {
      setLoanResetSignal(!loanResetSignal)
    }).catch(() => {
      console.error('Error Refreshing Loan')
    })
  }

  function openLinkedModalInner (relevantId: string, modalType: BlotterBigModal): void {
    if (modalType === 'transaction') {
      navigate(`/blotter/transactions/${relevantId}`)
    }
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: '95vw',
          height: '95vh',
          overflow: 'scroll'
        }}
      >
        <Stack
          direction={'column'}
          padding={'40px'}
          spacing={2}
        >
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            {
              (
                liabilitiesData !== undefined &&
                liabilitiesData !== null &&
                liabilitiesData.data.filter((liab) => (liab.closedTime === undefined || liab.closedTime === null)).length !== 0
              ) && <Button
                variant="outlined"
                sx={{ color: palette.accent.main }}
                onClick={() => { navigate(`/blotter/loans/close/${loanId}`) }}
              >
                <Typography textTransform="capitalize">Close</Typography>
              </Button>
            }
            <ModalOpenButton
              modalOpen={addModalOpen}
              setModalOpen={setAddModalOpen}
              modalContents={
                loan !== null
                  ? <AddTransactionToLoanModal loan={loan} submit={addTransactionToLoan}/>
                  : <></>
              }
            >
              <AddIcon />
            </ModalOpenButton>
            <RefreshButton
              refreshFunction={refresh}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Box>
          <LoanPanel loan={loan} isFetching={isLoanFetching} resetSignal={loanResetSignal}/>
          <SmallLegTable<LiabilityRow, Liability>
            title='Liabilities'
            columns={loanDetailsLiabilityColumns}
            data={liabilitiesData}
            openLinkedModal={
              (
                column: ColumnDefinition<LiabilityRow, BlotterInputType, Liability, string, BlotterBigModal>,
                row: LiabilityRow
              ) => {
                if (column.modalId !== undefined && column.modalType !== undefined) {
                  openLinkedModalInner(row[column.modalId], column.modalType)
                }
              }
            }
            translationFunciton={(change) => {
              return {
                ...change,
                topUpLevel: change.topUpLevel === undefined || change.topUpLevel === null ? '' : `${change.topUpLevel}`,
                interestRate: `${change.interestRate * 100}%`,
                account: `${change.account.platform.name}: ${change.account.name}`,
                strategy: (
                  change.strategy === undefined || change.strategy === null ? '' : `${change.strategy.superStrategy.name}: ${change.strategy.name}`
                ),
                desk: change.desk?.name ?? '',
                instrument: change.instrument.displayTicker,
                quantityDecimals: change.instrument.quantityDecimals,
                priceDecimals: change.instrument.priceDecimals,
                openTime: change.openedTime,
                openTransactionId: change.openedTransaction.id,
                closeTime: change.closedTime ?? '',
                closeTransactionId: change.closedTransaction?.id ?? '',
                counterparty: change.associatedLoan.counterparty.name,
                loanId: change.associatedLoan.id
              }
            }}
            pushFunction={pushLiabilities}
            setDataSignal={liabilitiesSetDataSignal}
            isFetching={isLiabilitiesFetching}
          />
        </Stack>
      </Box>
    </Modal>
  )
}

export default LoanDetails
