import React, { useEffect, useState } from 'react'
import type { Dayjs } from 'dayjs'
import { Box, useTheme } from '@mui/material'
import { EditableTable } from '@r40cap/ui'
import { type ReconciliationLog, reconciliationApi } from '@r40cap/pms-sdk'

import { historyColumns } from './constants'
import type { HistoryInputType, HistoryRow } from './types'
import { getEditModalContent } from './utils'

function HistoryTable (props: {
  startTime?: string
  endTime?: string
  limit?: number
  refreshSignal: boolean
  editedData: readonly ReconciliationLog[]
  setEditedData: (data: readonly ReconciliationLog[]) => void
  editedList: readonly string[]
  setEditedList: (data: readonly string[]) => void
  time: Dayjs
  useEntryTime: boolean
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    refreshSignal,
    editedData,
    setEditedData,
    editedList,
    setEditedList,
    time,
    useEntryTime
  } = props
  const [selected, setSelected] = useState<readonly string[]>([])

  const { data, refetch, isFetching } = reconciliationApi.useGetReconciliationLogsQuery({
    entryTime: useEntryTime ? time.format('YYYY-MM-DD HH:mm') : undefined,
    systemTime: !useEntryTime ? time.format('YYYY-MM-DD HH:mm') : undefined
  })

  function resetData (): void {
    if (data !== null && data !== undefined) {
      setEditedData(data.data)
      setEditedList([])
    }
  }

  useEffect(() => {
    resetData()
  }, [data])

  useEffect(() => {
    refetch().then(() => {
      resetData()
    }).catch(() => {
      console.error('Error Refreshing')
    })
  }, [refreshSignal])

  const getPreset = (rowId: string, property: keyof ReconciliationLog): any => {
    const relevantObject = editedData.find((dataRow: ReconciliationLog) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  const handleEdit = (property: keyof ReconciliationLog, value: any, selected: readonly string[]): void => {
    const updatedData = editedData.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    setEditedData(updatedData)
    const editedSet = new Set(editedList)
    selected.forEach((value) => {
      editedSet.add(value)
    })
    setEditedList(Array.from(editedSet))
  }

  return (
    <Box sx={{ height: '75vh' }}>
      <EditableTable<HistoryRow, ReconciliationLog, HistoryInputType, string>
        rows={editedData.map((value) => {
          const row: HistoryRow = {
            id: value.id,
            entryTime: value.entryTime,
            systemPositionsTime: value.systemPositionsTime,
            user: value.user?.username ?? '',
            account: `${value.account.platformName} - ${value.account.name}`,
            instrument: `${value.instrument.displayTicker}`,
            systemBalance: value.systemBalance,
            platformBalance: value.platformBalance,
            platformErrorExplanation: value.platformErrorExplanation,
            adminBalance: value.adminBalance,
            adminErrorExplanation: value.adminErrorExplanation,
            balanceDecimals: value.instrument.quantityDecimals
          }
          return row
        })}
        columns={historyColumns}
        selected={selected}
        defaultOrderBy={'entryTime'}
        defaultOrder='desc'
        setSelected={setSelected}
        isFetching={isFetching}
        getPreset={getPreset}
        getModalContent={(
          inputType: HistoryInputType,
          label: string,
          editProperty: keyof ReconciliationLog,
          closeModal: () => void,
          newSelected: readonly string[],
          initialValue: any
        ) => {
          return getEditModalContent(
            inputType,
            label,
            closeModal,
            (value: any) => {
              handleEdit(
                editProperty,
                value,
                newSelected
              )
            },
            setSelected,
            initialValue
          )
        }}
        checkboxColor={palette.accent.main}
        backgroundColor={palette.primary.main}
        headerBackgroundColor={palette.background.default}
        headerTextColor={palette.tableHeaderText.main}
        headerActiveTextColor={palette.accent.main}
        bodyDefaultTextColor={palette.tableBodyText.main}
        bodyPrimaryBackgroundColor={palette.tertiary.main}
        redTextColor='red'
        greenTextColor='green'
        footerBackgroundColor={palette.background.default}
        footerTextColor={palette.tableHeaderText.main}
        rowsPerPage={20}
      />
    </Box>
  )
}

export default HistoryTable
