import { type GlossarySelectOption } from './types'

export const allSelectOptions: GlossarySelectOption[] = [
  {
    type: 'strategies',
    display: 'Strategies'
  },
  {
    type: 'instruments',
    display: 'Instruments'
  },
  {
    type: 'entities',
    display: 'Entities'
  },
  {
    type: 'traders',
    display: 'Traders'
  },
  {
    type: 'accounts',
    display: 'Accounts'
  },
  {
    type: 'superStrategies',
    display: 'Super Strategies'
  },
  {
    type: 'baseFxs',
    display: 'Base FXs'
  },
  {
    type: 'instrumentTypes',
    display: 'Instrument Types'
  },
  {
    type: 'pricingMethods',
    display: 'Pricing Methods'
  },
  {
    type: 'investors',
    display: 'Investors'
  },
  {
    type: 'desks',
    display: 'Desks'
  }
]

export const primarySelectOptions: GlossarySelectOption[] = [
  {
    type: 'strategies',
    display: 'Strategies'
  },
  {
    type: 'accounts',
    display: 'Accounts'
  },
  {
    type: 'instruments',
    display: 'Instruments'
  },
  {
    type: 'traders',
    display: 'Traders'
  },
  {
    type: 'investors',
    display: 'Investors'
  },
  {
    type: 'desks',
    display: 'Desks'
  }
]
