import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, useTheme } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { type Investor, type CapitalCall, blotterApi } from '@r40cap/pms-sdk'

import CardDisplay from './CardDisplay'
import { BlotterInputType } from '../../common/types'
import PushButton from '../../../utils/PushButton'

function CapitalCallPanel (props: {
  capitalCall: CapitalCall | null
  isFetching: boolean
  resetSignal: boolean
}): React.JSX.Element {
  const { capitalCall, isFetching, resetSignal } = props
  const { palette } = useTheme()
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<React.JSX.Element>(<></>)
  const [editedCapitalCall, setEditedCapitalCall] = useState<CapitalCall | null>(capitalCall)
  const { showSnackbar } = useRequestSnackbar()

  useEffect(() => {
    setEditedCapitalCall(capitalCall)
  }, [capitalCall, resetSignal])

  const [pushEditedCapitalCall] = blotterApi.useEditCapitalCallByIdMutation()

  function setInvestor (value: Investor): void {
    if (editedCapitalCall !== null) {
      setEditedCapitalCall({
        ...editedCapitalCall,
        investor: value
      })
    }
  }

  function setMemo (value: string): void {
    if (editedCapitalCall !== null) {
      setEditedCapitalCall({
        ...editedCapitalCall,
        memo: value
      })
    }
  }

  function handlePush (): void {
    if (editedCapitalCall !== null) {
      showSnackbar({
        isOpen: true,
        message: 'Editing Capital Call',
        status: 'processing'
      })
      pushEditedCapitalCall({
        edit: {
          id: editedCapitalCall.id,
          investor: editedCapitalCall.investor,
          memo: editedCapitalCall.memo
        }
      })
        .then((value) => {
          showSnackbar({
            isOpen: true,
            message: 'Done',
            status: 'success'
          })
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to edit Capital Call',
            status: 'error'
          })
        })
    }
  }

  return (
    <>
      <Stack direction={'column'} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }} textAlign={'right'}>
          {
            editedCapitalCall !== capitalCall && <PushButton
              pushFunction={handlePush}
            />
          }
        </Box>
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={4} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<CapitalCall>
              title={'Investor'}
              value={editedCapitalCall?.investor.name}
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.INVESTOR}
              openModal={(content: React.JSX.Element) => {
                setModalContent(content)
                setEditModalOpen(true)
              }}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              editProperty='investor'
              onSubmission={setInvestor}
              enabled
              isFetching={isFetching}
            />
          </Grid>
          <Grid item xs={8} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<CapitalCall>
              title={'Memo'}
              value={editedCapitalCall?.memo}
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.STRING}
              openModal={(content: React.JSX.Element) => {
                setModalContent(content)
                setEditModalOpen(true)
              }}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              editProperty='memo'
              onSubmission={setMemo}
              enabled
              isFetching={isFetching}
            />
          </Grid>
        </Grid>
      </Stack>
      <Modal
        open={editModalOpen}
        handleClose={() => {
          setModalContent(<></>)
          setEditModalOpen(false)
        }}
      >
        {modalContent}
      </Modal>
    </>
  )
}

export default CapitalCallPanel
