import React, { useEffect, useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, Button, IconButton, Stack, Typography, CircularProgress, useTheme } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import ErrorIcon from '@mui/icons-material/Error'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import CheckIcon from '@mui/icons-material/Check'
import { Modal } from '@r40cap/ui'
import {
  getStrategyReport as sdkGetStrategy,
  getSnapshotReport as sdkGetSnapshot,
  getCounterpartyPnlReport as sdkGetCounterpartyPnl,
  getStrategyAccountReport as sdkGetStrategyAccount
} from '@r40cap/pms-sdk'

import { getLastMark } from '../../utils/times'

interface ReportDefinition {
  name: string
  deskName: string
  deskId?: string
  reportFunction: (time: Dayjs, deskId?: string) => Promise<boolean>
}

const reportsToGet: ReportDefinition[][] = [
  [
    {
      name: 'Strategy',
      deskName: 'Global',
      deskId: 'a4c29c1f-7c91-4258-ab1c-652c1089078d',
      // eslint-disable-next-line @typescript-eslint/return-await
      reportFunction: async (time: Dayjs, deskId?: string) => await sdkGetStrategy(time, 'Global_strategy', deskId)
    },
    {
      name: 'Snapshot',
      deskName: 'Global',
      deskId: 'a4c29c1f-7c91-4258-ab1c-652c1089078d',
      // eslint-disable-next-line @typescript-eslint/return-await
      reportFunction: async (time: Dayjs, deskId?: string) => await sdkGetSnapshot(time, 'Global_snapshot', deskId)
    },
    {
      name: 'Counterparty',
      deskName: 'Global',
      deskId: 'a4c29c1f-7c91-4258-ab1c-652c1089078d',
      // eslint-disable-next-line @typescript-eslint/return-await
      reportFunction: async (time: Dayjs, deskId?: string) => await sdkGetCounterpartyPnl(time, 'Global_cp_pnl', deskId)
    }
  ],
  [
    {
      name: 'Strategy',
      deskName: 'BD',
      deskId: '209b1408-beab-4d3a-b7ef-476040d7d6ff',
      // eslint-disable-next-line @typescript-eslint/return-await
      reportFunction: async (time: Dayjs, deskId?: string) => await sdkGetStrategy(time, 'BD_strategy', deskId)
    },
    {
      name: 'Snapshot',
      deskName: 'BD',
      deskId: '209b1408-beab-4d3a-b7ef-476040d7d6ff',
      // eslint-disable-next-line @typescript-eslint/return-await
      reportFunction: async (time: Dayjs, deskId?: string) => await sdkGetSnapshot(time, 'BD_snapshot', deskId)
    },
    {
      name: 'Counterparty',
      deskName: 'BD',
      deskId: '209b1408-beab-4d3a-b7ef-476040d7d6ff',
      // eslint-disable-next-line @typescript-eslint/return-await
      reportFunction: async (time: Dayjs, deskId?: string) => await sdkGetCounterpartyPnl(time, 'BD_cp_pnl', deskId)
    }
  ],
  [
    {
      name: 'Strategy',
      deskName: 'Aggregate',
      deskId: undefined,
      // eslint-disable-next-line @typescript-eslint/return-await
      reportFunction: async (time: Dayjs, deskId?: string) => await sdkGetStrategy(time, 'BD_strategy', deskId)
    },
    {
      name: 'Snapshot',
      deskName: 'Aggregate',
      deskId: undefined,
      // eslint-disable-next-line @typescript-eslint/return-await
      reportFunction: async (time: Dayjs, deskId?: string) => await sdkGetSnapshot(time, 'BD_snapshot', deskId)
    },
    {
      name: 'Counterparty',
      deskName: 'Aggregate',
      deskId: undefined,
      // eslint-disable-next-line @typescript-eslint/return-await
      reportFunction: async (time: Dayjs, deskId?: string) => await sdkGetCounterpartyPnl(time, 'BD_cp_pnl', deskId)
    }
  ]
]

function ProcessingItemComp (props: {
  time: Dayjs
  report: ReportDefinition
  startSignal: boolean
}): React.JSX.Element {
  const { time, report, startSignal } = props
  const { palette } = useTheme()
  const [status, setStatus] = useState<'pending' | 'success' | 'failure'>('pending')

  useEffect(() => {
    report.reportFunction(time, report.deskId)
      .then(() => {
        setStatus('success')
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [startSignal])

  return (
    <Stack direction={'column'} spacing={1} alignItems={'center'}>
      <Typography sx={{ color: palette.tableBodyText.main }}>{`${report.name}`}</Typography>
      {
        status === 'pending'
          ? <CircularProgress sx={{ color: palette.accent.main }}/>
          : status === 'success'
            ? <CheckIcon sx={{ color: palette.accent.main }} />
            : <ErrorIcon sx={{ color: palette.accent.main }} />
      }
    </Stack>
  )
}

function ProcessingRowComp (props: {
  row: ReportDefinition[]
  startSignal: boolean
  time: Dayjs
}): React.JSX.Element {
  const { row, time, startSignal } = props
  const { palette } = useTheme()

  const name = row.length > 0 ? row[0].deskName : 'Unknown'

  return (
    <Stack direction={'column'} spacing={1}>
      <Typography sx={{ color: palette.tableBodyText.main }}>{name}</Typography>
      <Stack direction={'row'} spacing={1}>
        {
          row.map((report: ReportDefinition, index: number) => (
            <ProcessingItemComp
              key={index}
              report={report}
              time={time}
              startSignal={startSignal}
            />
          ))
        }
      </Stack>
    </Stack>
  )
}

function ProcessingAllModal (props: {
  startSignal: boolean
  time: Dayjs
  goBack: () => void
}): React.JSX.Element {
  const { startSignal, time } = props

  return (
    <Box
      sx={{ width: '20vw', alignContent: 'center' }}
      padding={5}
    >
      <Stack direction={'column'} sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
        {
          reportsToGet.map((row: ReportDefinition[], index: number) => (
            <ProcessingRowComp
              key={index}
              row={row}
              time={time}
              startSignal={startSignal}
            />
          ))
        }
      </Stack>
    </Box>
  )
}

function ProcessingOneModal (props: {
  startSignal: boolean
  reportName: string
  deskName: string
  deskId?: string
  reportFunction: (time: Dayjs, deskId?: string) => Promise<boolean>
  time: Dayjs
  goBack: () => void
}): React.JSX.Element {
  const { reportName, deskName, time, deskId, reportFunction, startSignal } = props

  return (
    <Box
      sx={{ width: '20vw', alignContent: 'center' }}
      padding={5}
    >
      <Stack direction={'column'} sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
        <ProcessingItemComp
          time={time}
          report={{
            name: reportName,
            deskName,
            deskId,
            reportFunction
          }}
          startSignal={startSignal}
        />
      </Stack>
    </Box>
  )
}

interface DeskInfo {
  name: string
  id?: string
}

function EntryModal (props: {
  getReports: (time: Dayjs) => void
  getStrategyReport: (time: Dayjs, desk: DeskInfo) => void
  getSnapshotReport: (time: Dayjs, desk: DeskInfo) => void
  getCounterpartyPnlReport: (time: Dayjs, desk: DeskInfo) => void
  getStrategyAccountReport: (time: Dayjs, desk: DeskInfo) => void
  time: Dayjs
  setTime: (time: Dayjs) => void
}): React.JSX.Element {
  const {
    getReports,
    getSnapshotReport,
    getCounterpartyPnlReport,
    getStrategyReport,
    getStrategyAccountReport,
    time,
    setTime
  } = props
  const { palette } = useTheme()

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  return (
    <Box
      sx={{ width: '40vw', alignContent: 'center' }}
      padding={5}
    >
      <Stack direction={'column'} sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
        <DateTimePicker
          value={time}
          format='YYYY-MM-DD HH:mm'
          onChange={handleChangeTime}
          closeOnSelect={false}
          ampm={false}
          timeSteps={{ hours: 1, minutes: 1 }}
        />
        <Stack direction={'row'} spacing={1}>
          <Button
            variant='outlined'
            onClick={() => { setTime(getLastMark()) }}
            sx={{
              color: palette.accent.main,
              width: '50%'
            }}
          >
            Last Mark
          </Button>
          <Button
            variant='outlined'
            onClick={() => { setTime(dayjs().utc()) }}
            sx={{
              color: palette.accent.main,
              width: '50%'
            }}
          >
            Now
          </Button>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <Button
            variant='contained'
            onClick={() => {
              getStrategyReport(
                time,
                {
                  name: 'Global',
                  id: 'a4c29c1f-7c91-4258-ab1c-652c1089078d'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            Global Srategy
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              getSnapshotReport(
                time,
                {
                  name: 'Global',
                  id: 'a4c29c1f-7c91-4258-ab1c-652c1089078d'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            Global Snapshot
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              getCounterpartyPnlReport(
                time,
                {
                  name: 'Global',
                  id: 'a4c29c1f-7c91-4258-ab1c-652c1089078d'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            Global Counterparty
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              getStrategyAccountReport(
                time,
                {
                  name: 'Global',
                  id: 'a4c29c1f-7c91-4258-ab1c-652c1089078d'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            Global Strat-Acc
          </Button>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <Button
            variant='contained'
            onClick={() => {
              getStrategyReport(
                time,
                {
                  name: 'BD',
                  id: '209b1408-beab-4d3a-b7ef-476040d7d6ff'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            BD Srategy
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              getSnapshotReport(
                time,
                {
                  name: 'BD',
                  id: '209b1408-beab-4d3a-b7ef-476040d7d6ff'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            BD Snapshot
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              getCounterpartyPnlReport(
                time,
                {
                  name: 'BD',
                  id: '209b1408-beab-4d3a-b7ef-476040d7d6ff'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            BD Counterparty
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              getStrategyAccountReport(
                time,
                {
                  name: 'BD',
                  id: '209b1408-beab-4d3a-b7ef-476040d7d6ff'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            BD Strat-Acc
          </Button>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <Button
            variant='contained'
            onClick={() => {
              getStrategyReport(
                time,
                {
                  name: 'Aggregate'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            Agg. Srategy
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              getSnapshotReport(
                time,
                {
                  name: 'Aggregate'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            Agg. Snapshot
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              getCounterpartyPnlReport(
                time,
                {
                  name: 'Aggregate'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            Agg. Counterparty
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              getStrategyAccountReport(
                time,
                {
                  name: 'Aggregate'
                }
              )
            }}
            sx={{
              backgroundColor: palette.accent.main,
              color: palette.background.default,
              width: '100%'
            }}
          >
            Agg. Strat-Acc
          </Button>
        </Stack>
        <Button
          variant='contained'
          onClick={() => { getReports(time) }}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.background.default,
            width: '100%'
          }}
        >
          All Reports
        </Button>
      </Stack>
    </Box>
  )
}

export function ReportButton (): React.JSX.Element {
  dayjs.extend(utc)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [isProcessingAll, setIsProcessingAll] = useState<boolean>(false)
  const [isProcessingOne, setIsProcessingOne] = useState<boolean>(false)
  const [time, setTime] = useState<Dayjs>(dayjs().utc())
  const [allStartSignal, setAllStartSignal] = useState<boolean>(false)
  const [oneStartSignal, setOneStartSignal] = useState<boolean>(false)

  const [reportName, setReportName] = useState<string>('')
  const [deskName, setDeskName] = useState<string>('')
  const [deskId, setDeskId] = useState<string | undefined>(undefined)
  const [reportType, setReportType] = useState<'cp' | 'ss' | 'st' | 'sa'>('cp')

  function closeModal (): void {
    setModalOpen(false)
    setIsProcessingAll(false)
    setIsProcessingOne(false)
  }

  function getReports (): void {
    setIsProcessingAll(true)
    setAllStartSignal(!allStartSignal)
  }

  return (
    <>
      <IconButton onClick={() => { setModalOpen(true) }}>
        <FileDownloadIcon/>
      </IconButton>
      <Modal
        open={modalOpen}
        handleClose={closeModal}
      >
        {
          isProcessingAll
            ? <ProcessingAllModal
                time={time}
                startSignal={allStartSignal}
                goBack={() => { setIsProcessingAll(false) }}
              />
            : isProcessingOne
              ? <ProcessingOneModal
                  startSignal={oneStartSignal}
                  reportName={reportName}
                  deskName={deskName}
                  deskId={deskId}
                  time={time}
                  reportFunction={
                    reportType === 'cp'
                      // eslint-disable-next-line @typescript-eslint/return-await
                      ? async (time: Dayjs, deskId?: string) => await sdkGetCounterpartyPnl(
                        time,
                        `${deskName}_cp_pnl`,
                        deskId
                      )
                      : reportType === 'ss'
                        // eslint-disable-next-line @typescript-eslint/return-await
                        ? async (time: Dayjs, deskId?: string) => await sdkGetSnapshot(
                          time,
                          `${deskName}_snapshot`,
                          deskId
                        )
                        : reportType === 'sa'
                          // eslint-disable-next-line @typescript-eslint/return-await
                          ? async (time: Dayjs, deskId?: string) => await sdkGetStrategyAccount(
                            time,
                            `${deskName}_strategy_account`,
                            deskId
                          )
                          // eslint-disable-next-line @typescript-eslint/return-await
                          : async (time: Dayjs, deskId?: string) => await sdkGetStrategy(
                            time,
                            `${deskName}_strategy`,
                            deskId
                          )
                  }
                  goBack={() => { setIsProcessingOne(false) }}
                />
              : <EntryModal
                  getReports={getReports}
                  getCounterpartyPnlReport={
                    (time: Dayjs, desk: DeskInfo) => {
                      setReportName('Counterparty')
                      setDeskName(desk.name)
                      setDeskId(desk.id)
                      setReportType('cp')
                      setTime(time)
                      setIsProcessingOne(true)
                      setOneStartSignal(!oneStartSignal)
                    }
                  }
                  getSnapshotReport={
                    (time: Dayjs, desk: DeskInfo) => {
                      setReportName('Snapshot')
                      setDeskName(desk.name)
                      setDeskId(desk.id)
                      setReportType('ss')
                      setTime(time)
                      setIsProcessingOne(true)
                      setOneStartSignal(!oneStartSignal)
                    }
                  }
                  getStrategyReport={
                    (time: Dayjs, desk: DeskInfo) => {
                      setReportName('Strategy')
                      setDeskName(desk.name)
                      setDeskId(desk.id)
                      setReportType('st')
                      setTime(time)
                      setIsProcessingOne(true)
                      setOneStartSignal(!oneStartSignal)
                    }
                  }
                  getStrategyAccountReport={
                    (time: Dayjs, desk: DeskInfo) => {
                      setReportName('Strategy-Account')
                      setDeskName(desk.name)
                      setDeskId(desk.id)
                      setReportType('sa')
                      setTime(time)
                      setIsProcessingOne(true)
                      setOneStartSignal(!oneStartSignal)
                    }
                  }
                  time={time}
                  setTime={setTime}
                />
        }
      </Modal>
    </>
  )
}
