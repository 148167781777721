import {
  type BlotterSelectOption,
  type TimingOption,
  TimingType
} from './types'

export const selectOptions: BlotterSelectOption[] = [
  {
    type: 'transactions',
    display: 'Transactions'
  },
  {
    type: 'loans',
    display: 'Loans'
  },
  {
    type: 'expenses',
    display: 'Expenses'
  },
  {
    type: 'capitalCalls',
    display: 'Capital Calls'
  },
  {
    type: 'unsettled',
    display: 'Unsettled'
  }
]

export const timingOptions: TimingOption[] = [
  {
    type: TimingType.LIVE,
    label: 'Live',
    value: 'live'
  },
  {
    type: TimingType.TIME,
    label: 'Time',
    value: 'time'
  }
]
