import type { LoanAdditionSelectOption, TransactionAdditionSelectOption } from './types'

export const loanSelectOptions: LoanAdditionSelectOption[] = [
  {
    type: 'basicLoan',
    display: 'Basic Loan',
    width: 90
  },
  {
    type: 'custom',
    display: 'Custom',
    width: 90
  }
]

export const transactionSelectOptions: TransactionAdditionSelectOption[] = [
  {
    type: 'feeTrade',
    display: 'Trade w/Fee',
    width: 80
  },
  {
    type: 'strategyTransfer',
    display: 'Strategy Transfer',
    width: 80
  },
  {
    type: 'accountTransfer',
    display: 'Account Transfer',
    width: 80
  },
  {
    type: 'custom',
    display: 'Custom',
    width: 90
  }
]
