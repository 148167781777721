export interface PnlRow {
  title: string
  day: number
  week: number
  month: number
  year: number
  inception: number
}

export enum PnlTimeType {
  CURRENT,
  LAST_MARK,
  EOD
}

export interface PnlTimeOption {
  type: PnlTimeType
  label: string
  value: string
}
