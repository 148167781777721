import React, { useEffect, useState } from 'react'
import { Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  type EditableRowObject,
  EditableTable,
  type ColumnDefinition,
  type ObjectWithId,
  ModalOpenButton
} from '@r40cap/ui'

import type { BlotterBigModal } from './types'
import type { BlotterInputType } from '../common/types'
import { getBlotterEditModalContent } from '../utils'
import type { GetResponse } from '@r40cap/pms-sdk'
import PushButton from '../../utils/PushButton'

function shouldShowTable<O> (
  editedData: O[],
  isFetching: boolean
): boolean {
  return (
    isFetching as boolean || editedData.length > 0
  )
}

function SmallLegTable<R extends ObjectWithId<string>, O extends ObjectWithId<string>> (props: {
  title: string
  columns: Array<ColumnDefinition<R, BlotterInputType, O, string, BlotterBigModal>>
  data: GetResponse<O[]> | undefined | null
  isFetching: boolean
  translationFunciton: (object: O) => EditableRowObject<R, string>
  setDataSignal: boolean
  openLinkedModal?: (column: ColumnDefinition<R, BlotterInputType, O, string, BlotterBigModal>, row: R) => void
  getAddModalContents?: (submitFunction: (object: O) => void) => React.JSX.Element
  pushFunction?: (additions: O[], edits: O[]) => void
  handleAction?: (selectedIds: readonly string[]) => void
  actionLabel?: string
}): React.JSX.Element {
  const {
    title,
    columns,
    data,
    isFetching,
    translationFunciton,
    setDataSignal,
    getAddModalContents,
    openLinkedModal,
    pushFunction,
    handleAction,
    actionLabel
  } = props
  const { palette } = useTheme()

  const [addModalOpen, setAddModalOpen] = useState<boolean>(false)
  const [editedData, setEditedData] = useState<O[]>([])
  const [addedList, setAddedList] = useState<readonly string[]>([])
  const [editedList, setEditedList] = useState<readonly string[]>([])
  const [selectedIds, setSelectedIds] = useState<readonly string[]>([])

  const getPreset = (rowId: string, property: keyof O): any => {
    const relevantObject = editedData.find((dataRow: O) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  function appendData (addition: O): void {
    const addedSet = new Set(addedList)
    addedSet.add(addition.id)
    setAddedList(Array.from(addedSet))
    setEditedData(editedData.concat(addition))
  }

  function submitAddition (addition: O): void {
    appendData(addition)
    setAddModalOpen(false)
  }

  const handleEdit = (property: keyof O, value: any, selected: readonly string[]): void => {
    const updatedData = editedData.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    setEditedData(updatedData)
    const editedSet = new Set(editedList)
    selected.forEach((value) => {
      if (!(addedList.includes(value) as boolean)) {
        editedSet.add(value)
      }
    })
    setEditedList(Array.from(editedSet))
  }

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setEditedData(data.data)
    }
  }, [data])

  useEffect(() => {
    setEditedData(data?.data ?? [])
    setAddedList([])
    setEditedList([])
  }, [setDataSignal])

  return (
    <Stack
      direction={'column'}
      sx={{
        height: shouldShowTable(editedData, isFetching) ? '40vh' : undefined
      }}
    >
      <Grid container sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Grid item xs={6} textAlign={'left'}>
          {
            shouldShowTable(editedData, isFetching)
              ? <Typography fontSize={20} color={palette.tableBodyText.main}>
                {title.toUpperCase()}
              </Typography>
              : <Typography fontSize={20} color={palette.tableBodyText.main}>
                NO {title.toUpperCase()}
              </Typography>
          }
        </Grid>
        <Grid item xs={6} textAlign={'right'}>
          {
            getAddModalContents !== undefined && (
              <ModalOpenButton
              modalOpen={addModalOpen}
              setModalOpen={setAddModalOpen}
              modalContents={
                getAddModalContents(submitAddition)
              }
              >
                <AddIcon />
              </ModalOpenButton>
            )
          }
          {
            selectedIds.length > 0 && handleAction !== undefined && <Button
              variant='contained'
              sx={{
                height: '75%',
                backgroundColor: palette.accent.main,
                color: palette.background.default
              }}
              onClick={() => { handleAction(selectedIds) }}
            >
              {actionLabel ?? ''}
            </Button>
          }
          {
            (addedList.length > 0 || editedList.length > 0) && pushFunction !== undefined &&
            <PushButton
              pushFunction={() => {
                const newAdditionObjects: O[] = editedData.filter((data) => addedList.includes(data.id))
                const editedEditObjects: O[] = editedData.filter((data) => editedList.includes(data.id))
                pushFunction(newAdditionObjects, editedEditObjects)
              }}
            />
          }
        </Grid>
      </Grid>
      {shouldShowTable(editedData, isFetching) && <EditableTable<R, O, BlotterInputType, string, BlotterBigModal>
        rows={editedData.map((originalObject) => {
          return translationFunciton(originalObject)
        })}
        columns={columns}
        selected={selectedIds}
        setSelected={setSelectedIds}
        defaultOrderBy={columns.at(0)?.id ?? 'id'}
        defaultOrder='desc'
        isFetching={isFetching}
        getPreset={getPreset}
        getModalContent={(
          inputType: BlotterInputType,
          label: string,
          editProperty: keyof O,
          closeModal: () => void,
          newSelected: readonly string[],
          initialValue: any
        ) => {
          return getBlotterEditModalContent<O>(
            inputType,
            label,
            editProperty,
            closeModal,
            handleEdit,
            newSelected,
            setSelectedIds,
            initialValue
          )
        }}
        openLinkedModal={openLinkedModal}
        checkboxColor={palette.accent.main}
        backgroundColor={palette.primary.main}
        headerBackgroundColor={palette.background.default}
        headerTextColor={palette.tableHeaderText.main}
        headerActiveTextColor={palette.accent.main}
        bodyDefaultTextColor={palette.tableBodyText.main}
        bodyPrimaryBackgroundColor={palette.tertiary.main}
        redTextColor='red'
        greenTextColor='green'
        dense
        blankId=''
        rowsPerPage={10}
        footerBackgroundColor={palette.background.default}
        footerTextColor={palette.tableHeaderText.main}
      />}
    </Stack>
  )
}

export default SmallLegTable
