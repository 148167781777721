import React, { useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, Stack } from '@mui/material'
import { useRequestSnackbar } from '@r40cap/ui'
import {
  type ReconciliationLogSubmission,
  reconciliationApi,
  type ReconciliationLog
} from '@r40cap/pms-sdk'

import type { ReconciliationOption } from '../types'
import HistoryControlsPanel from './HistoryControlsPanel'
import HistoryTable from './HistoryTable'

function HistoryView (props: {
  viewType: ReconciliationOption
  setViewType: (value: ReconciliationOption) => void
}): React.JSX.Element {
  dayjs.extend(utc)
  const {
    viewType,
    setViewType
  } = props
  const { showSnackbar } = useRequestSnackbar()
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const [useEntryTime, setUseEntryTime] = useState<boolean>(true)
  const [editedList, setEditedList] = useState<readonly string[]>([])
  const [editedData, setEditedData] = useState<readonly ReconciliationLog[]>([])
  const [time, setTime] = useState<Dayjs>(dayjs().utc())

  const [editEntries] = reconciliationApi.useEditReconciliationEntriesMutation()

  function pushData (): void {
    const editedEditObjects: ReconciliationLogSubmission[] = editedData.filter(
      (data) => editedList.includes(data.id)
    ).map((log) => {
      const submission: ReconciliationLogSubmission = {
        ...log,
        account: log.account.id,
        instrument: log.instrument.id
      }
      return submission
    })
    if (editedEditObjects.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Pushing Updates',
        status: 'processing'
      })
      editEntries({
        edits: editedEditObjects
      })
        .then((value) => {
          showSnackbar({
            isOpen: true,
            message: 'Updates Pushed',
            status: 'success'
          })
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push updates',
            status: 'error'
          })
        })
    }
    setEditedList([])
  }

  return (
    <Stack
      spacing={1}
      direction={'column'}
      width={'100%'}
      sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
    >
      <Box sx={{ height: '7%' }}>
        <HistoryControlsPanel
          refreshFunction={() => { setRefreshSignal(!(refreshSignal as boolean)) }}
          showPushButton={editedList.length > 0}
          pushFunction={pushData}
          viewType={viewType}
          setViewType={setViewType}
          time={time}
          setTime={setTime}
          useEntryTime={useEntryTime}
          setUseEntryTime={setUseEntryTime}
        />
      </Box>
      <Box sx={{ height: '93%' }}>
        <HistoryTable
          refreshSignal={refreshSignal}
          editedData={editedData}
          setEditedData={setEditedData}
          editedList={editedList}
          setEditedList={setEditedList}
          time={time}
          useEntryTime={useEntryTime}
        />
      </Box>
    </Stack>
  )
}

export default HistoryView
