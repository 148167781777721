import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, useTheme } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { blotterApi, type Entity, type Loan } from '@r40cap/pms-sdk'

import CardDisplay from './CardDisplay'
import { BlotterInputType } from '../../common/types'
import PushButton from '../../../utils/PushButton'

function LoanPanel (props: {
  loan: Loan | null
  isFetching: boolean
  resetSignal: boolean
}): React.JSX.Element {
  const { loan, isFetching, resetSignal } = props
  const { palette } = useTheme()
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<React.JSX.Element>(<></>)
  const [editedLoan, setEditedLoan] = useState<Loan | null>(loan)
  const { showSnackbar } = useRequestSnackbar()

  useEffect(() => {
    setEditedLoan(loan)
  }, [loan, resetSignal])

  const [pushEditedLoan] = blotterApi.useEditLoanByIdMutation()

  function setCounterparty (value: Entity): void {
    if (editedLoan !== null) {
      setEditedLoan({
        ...editedLoan,
        counterparty: value
      })
    }
  }

  function setExpiration (value: string): void {
    if (editedLoan !== null) {
      setEditedLoan({
        ...editedLoan,
        expiration: value
      })
    }
  }

  function handlePush (): void {
    if (editedLoan !== null) {
      showSnackbar({
        isOpen: true,
        message: 'Editing Loan',
        status: 'processing'
      })
      pushEditedLoan({
        edit: {
          id: editedLoan.id,
          counterparty: editedLoan.counterparty,
          expiration: editedLoan.expiration
        }
      })
        .then((value) => {
          showSnackbar({
            isOpen: true,
            message: 'Loan Edited',
            status: 'success'
          })
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Error Editing Loan',
            status: 'error'
          })
        })
    }
  }

  return (
    <>
      <Stack direction={'column'} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }} textAlign={'right'}>
          {
            editedLoan !== loan && <PushButton
              pushFunction={handlePush}
            />
          }
        </Box>
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={4} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<Loan>
              title={'Counterparty'}
              value={editedLoan?.counterparty.name}
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.ENTITY}
              openModal={(content: React.JSX.Element) => {
                setModalContent(content)
                setEditModalOpen(true)
              }}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              editProperty='counterparty'
              onSubmission={setCounterparty}
              enabled
              isFetching={isFetching}
            />
          </Grid>
          <Grid item xs={4} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<Loan>
              title={'Expiration'}
              value={
                editedLoan !== null
                  ? editedLoan.expiration
                  : null
              }
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.TIME}
              openModal={(content: React.JSX.Element) => {
                setModalContent(content)
                setEditModalOpen(true)
              }}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              editProperty='expiration'
              onSubmission={setExpiration}
              enabled
              isFetching={isFetching}
            />
          </Grid>
        </Grid>
      </Stack>
      <Modal
        handleClose={() => {
          setModalContent(<></>)
          setEditModalOpen(false)
        }}
        open={editModalOpen}
      >
        {modalContent}
      </Modal>
    </>
  )
}

export default LoanPanel
