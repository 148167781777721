import type { ColumnDefinition } from '@r40cap/ui'

import { UpdatingInputType, type UpdatingRow, type UnmatchedRow } from './types'

export const updatingColumns: Array<ColumnDefinition<UpdatingRow, UpdatingInputType, UpdatingRow>> = [
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 8,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'ourBalance',
    label: 'System',
    columnWidthPercentage: 10,
    inputType: UpdatingInputType.NUMBER,
    editId: 'ourBalance',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'balanceDecimals'
  },
  {
    id: 'platformBalance',
    label: 'Platform',
    columnWidthPercentage: 10,
    inputType: UpdatingInputType.NUMBER,
    editId: 'platformBalance',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'balanceDecimals'
  },
  {
    id: 'platformErrorExplanation',
    label: 'Error Reason',
    columnWidthPercentage: 15,
    inputType: UpdatingInputType.TEXT,
    editId: 'platformErrorExplanation',
    overflowType: 'scroll',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'adminBalance',
    label: 'NAV',
    columnWidthPercentage: 10,
    inputType: UpdatingInputType.NUMBER,
    editId: 'adminBalance',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'balanceDecimals'
  },
  {
    id: 'adminErrorExplanation',
    label: 'NAV Error Reason',
    columnWidthPercentage: 15,
    inputType: UpdatingInputType.TEXT,
    editId: 'adminErrorExplanation',
    overflowType: 'scroll',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]

export const unmatchedColumns: Array<ColumnDefinition<UnmatchedRow, UpdatingInputType, UnmatchedRow>> = [
  {
    id: 'ticker',
    label: 'Ticker',
    columnWidthPercentage: 45,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'platformBalance',
    label: 'Balance',
    columnWidthPercentage: 45,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]
