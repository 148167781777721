import React, { type Key } from 'react'
import {
  IconButton,
  TableCell,
  TableRow,
  useTheme
} from '@mui/material'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import { ValueCell } from '@r40cap/ui'
import { type BaseFxPnl } from '@r40cap/pms-sdk'

import { pnlColumns } from '../constants'
import { type PnlRow } from '../types'

function BaseFxRow (props: { baseFxPnl: BaseFxPnl }): React.JSX.Element {
  const { baseFxPnl } = props
  const { palette } = useTheme()

  return (
    <TableRow
      sx={{ backgroundColor: palette.secondary.main }}
    >
      <TableCell key={'toggle'}>
        <IconButton
          aria-label="expand row"
          size="small"
          disabled
        >
          <SubdirectoryArrowRightIcon fontSize='small'/>
        </IconButton>
        <IconButton
          aria-label="expand row"
          size="small"
          disabled
        >
          <SubdirectoryArrowRightIcon fontSize='small'/>
        </IconButton>
      </TableCell>
      {
        pnlColumns.map((column) => (
          <ValueCell<PnlRow, any>
            column={column}
            item={{
              title: `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${baseFxPnl.name}`,
              day: baseFxPnl.pnlSnapshot.day,
              week: baseFxPnl.pnlSnapshot.week,
              month: baseFxPnl.pnlSnapshot.month,
              year: baseFxPnl.pnlSnapshot.year,
              inception: baseFxPnl.pnlSnapshot.inception
            }}
            defaultTextColor={palette.tableBodyText.main}
            redTextColor='red'
            greenTextColor='green'
            key={column.id as Key}
          />
        ))
      }
    </TableRow>
  )
}

export default BaseFxRow
