import React from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  useTheme
} from '@mui/material'

import { type DeskOption } from './types'
import { deskOptions } from './constants'

function DeskSelection (props: {
  currentDesk: DeskOption
  deskChange: (view: DeskOption) => void
}): React.JSX.Element {
  const { currentDesk, deskChange } = props
  const { palette } = useTheme()

  const handleDeskChange = (event: SelectChangeEvent): void => {
    const chosenOption = deskOptions.find((option) => option.id === event.target.value)
    if (chosenOption !== undefined) {
      deskChange(chosenOption)
    }
  }

  return (
    <FormControl
      sx={{ width: '100%', height: '100%' }}
    >
      <InputLabel
        sx={{ color: palette.tableBodyText.main }}
      >
        Desk
      </InputLabel>
      <Select
        value={currentDesk.id}
        onChange={handleDeskChange}
        sx={{ width: '100%', height: '100%' }}
        color="accent"
      >
        {deskOptions.map(
          (option, index) => (
            <MenuItem key={index} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default DeskSelection
