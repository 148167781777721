import type {
  ColumnDefinitionWithColor,
  ColumnDefinitionWithStaleness,
  PortfolioRow,
  PositionRow,
  LiabilityRow
} from './types'

export const UNTAGGED_ID = 'untagged'
export const UNTAGGED_PRIORITY = 10000
export const UNTAGGED_COLOR = '#FFFFFF'
export const UNTAGGED_NAME = 'Untagged'

export const strategyColumns: Array<ColumnDefinitionWithColor<PositionRow, any>> = [
  {
    id: 'priorityLevel',
    label: 'Pr.',
    columnWidthPercentage: 5,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'name',
    label: 'Strategy',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    indicatorColorId: 'strategyColor'
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'multiplier',
    label: 'Mult.',
    columnWidthPercentage: 3,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    id: 'delta',
    label: 'Delta',
    columnWidthPercentage: 3,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'deltaQuantity',
    label: 'Delta Amount',
    columnWidthPercentage: 12,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 12,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals',
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Price is'
    }
  },
  {
    id: 'marketValue',
    label: 'MV',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'MV calc using price that is'
    }
  },
  {
    id: 'refrenceMarketValue',
    label: '',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  }
]

export const accountColumns: Array<ColumnDefinitionWithStaleness<PortfolioRow, any>> = [
  {
    id: 'name',
    label: 'Account',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'multiplier',
    label: 'Mult.',
    columnWidthPercentage: 3,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    id: 'delta',
    label: 'Delta',
    columnWidthPercentage: 3,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'deltaQuantity',
    label: 'Delta Amount',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals',
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Price is'
    }
  },
  {
    id: 'marketValue',
    label: 'MV',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'MV calc using price that is'
    }
  },
  {
    id: 'refrenceMarketValue',
    label: '',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  }
]

export const counterpartyColumns: Array<ColumnDefinitionWithStaleness<LiabilityRow, any>> = [
  {
    id: 'name',
    label: 'Counterparty',
    columnWidthPercentage: 18,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'multiplier',
    label: 'Mult.',
    columnWidthPercentage: 3,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  },
  {
    id: 'effectiveInterestRate',
    label: 'Rate',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: '%'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals',
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'Price is'
    }
  },
  {
    id: 'marketValue',
    label: 'MV',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2,
    stalenessInfo: {
      recievedTimeId: 'receivedTime',
      requestedTimeId: 'requestedTime',
      stalenessPrefix: 'MV calc using price that is'
    }
  },
  {
    id: 'refrenceMarketValue',
    label: '',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 0
  }
]
