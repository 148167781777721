import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type Dayjs } from 'dayjs'
import {
  Button,
  IconButton,
  MenuItem,
  Stack,
  Select,
  type SelectChangeEvent,
  useTheme,
  Box,
  Typography
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import { RefreshButton, BooleanInput } from '@r40cap/ui'
import { useAuth } from '@r40cap/auth'
import {
  AccountInput,
  type LiteAccount,
  type LiteStrategy,
  type LiteInstrument,
  StrategyInput,
  InstrumentInput,
  type LiteDesk,
  type LiteBaseFx,
  DeskInput,
  BaseFxInput
} from '@r40cap/pms-sdk'

import BlotterViewSelection from './BlotterViewSelection'
import { TagContent } from './modals'
import { timingOptions } from './constants'
import {
  type BlotterOption,
  type TimingOption,
  type BlotterFilterType,
  type BlotterFilterPiece,
  TimingType
} from './types'
import type { FilterPiece } from '../common/types'

function TimingSelection (props: {
  timing: TimingOption
  timingChangeFunction: (option: TimingOption) => void
}): React.JSX.Element {
  const { timing, timingChangeFunction } = props

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const chosenOption = timingOptions.find((option) => option.value === event.target.value)
    if (chosenOption !== undefined) {
      timingChangeFunction(chosenOption)
    }
  }

  return (
    <Select
      value={timing.value}
      onChange={handleTypeChange}
      sx={{ width: '100%' }}
      color="accent"
    >
      {timingOptions.map(
        (option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  )
}

function FilterDescription (props: {
  name: string
  values: FilterPiece[]
  clearFunction: () => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    name,
    values,
    clearFunction
  } = props

  return (
    <Box
      sx={{
        backgroundColor: palette.background.default,
        borderRadius: '5px',
        alignContent: 'center'
      }}
    >
      <Stack
        direction={'row'}
        sx={{ alignItems: 'center' }}
      >
        <Typography sx={{ fontSize: 10, padding: 1, color: palette.tableBodyText.main }}>
          {name} in [{values.map((value) => value.name).join(', ')}]
        </Typography>
        <IconButton
          onClick={clearFunction}
          sx={{ padding: 1, color: 'red' }}
        >
          <ClearIcon fontSize='small'/>
        </IconButton>
      </Stack>
    </Box>
  )
}

function AccountFilterContent (props: {
  submitFunction: (value: FilterPiece) => void
}): React.JSX.Element {
  const { submitFunction } = props
  const [choice, setChoice] = useState<FilterPiece | null>(null)
  const { palette } = useTheme()

  return (
    <Box
      sx={{ width: '25vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <AccountInput
          setAccount={(account: LiteAccount | null) => {
            if (account !== null) {
              setChoice({ id: account.id, name: `${account.platform.name} - ${account.name}` })
            } else {
              setChoice(null)
            }
          }}
          setNone={() => {}}
          allowNone={false}
          enhanced={false}
          includeEntityFilter={true}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
        />
        <Button
          variant='contained'
          onClick={() => {
            if (choice !== null) {
              submitFunction(choice)
            }
          }}
          disabled={choice === null}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

function DeskFilterContent (props: {
  submitFunction: (value: FilterPiece) => void
}): React.JSX.Element {
  const { submitFunction } = props
  const [choice, setChoice] = useState<FilterPiece | null>(null)
  const { palette } = useTheme()

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={2}>
        <DeskInput
          setDesk={(desk: LiteDesk | null) => {
            if (desk !== null) {
              setChoice({ id: desk.id, name: desk.name })
            } else {
              setChoice(null)
            }
          }}
          allowNone={true}
          setNone={(isNone) => { if (isNone as boolean) { setChoice({ id: 'none', name: 'None' }) } }}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced={false}
        />
        <Button
          variant='contained'
          onClick={() => {
            if (choice !== null) {
              submitFunction(choice)
            }
          }}
          disabled={choice === null}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

function StrategyFilterContent (props: {
  submitFunction: (value: FilterPiece) => void
}): React.JSX.Element {
  const { submitFunction } = props
  const [choice, setChoice] = useState<FilterPiece | null>(null)
  const { palette } = useTheme()

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <StrategyInput
          setStrategy={(strategy: LiteStrategy | null) => {
            if (strategy !== null) {
              setChoice({ id: strategy.id, name: `${strategy.superStrategy.name} - ${strategy.name}` })
            } else {
              setChoice(null)
            }
          }}
          allowNone={true}
          setNone={(isNone) => { if (isNone as boolean) { setChoice({ id: 'none', name: 'None' }) } }}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced={false}
        />
        <Button
          variant='contained'
          onClick={() => {
            if (choice !== null) {
              submitFunction(choice)
            }
          }}
          disabled={choice === null}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

function InstrumentFilterContent (props: {
  submitFunction: (value: FilterPiece) => void
}): React.JSX.Element {
  const { submitFunction } = props
  const [choice, setChoice] = useState<FilterPiece | null>(null)
  const { palette } = useTheme()

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <InstrumentInput
          setInstrument={(instrument: LiteInstrument | null) => {
            if (instrument !== null) {
              setChoice({ id: instrument.id, name: instrument.displayTicker })
            } else {
              setChoice(null)
            }
          }}
          allowNone={false}
          setNone={() => {}}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          putColor='red'
          callColor='green'
          enhanced={false}
        />
        <Button
          variant='contained'
          onClick={() => {
            if (choice !== null) {
              submitFunction(choice)
            }
          }}
          disabled={choice === null}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

function BaseFxFilterContent (props: {
  submitFunction: (value: FilterPiece) => void
}): React.JSX.Element {
  const { submitFunction } = props
  const [choice, setChoice] = useState<FilterPiece | null>(null)
  const { palette } = useTheme()

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <BaseFxInput
          setBaseFx={(baseFx: LiteBaseFx | null) => {
            if (baseFx !== null) {
              setChoice({ id: baseFx.id, name: baseFx.ticker })
            } else {
              setChoice(null)
            }
          }}
          allowNone={false}
          setNone={() => {}}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced={false}
        />
        <Button
          variant='contained'
          onClick={() => {
            if (choice !== null) {
              submitFunction(choice)
            }
          }}
          disabled={choice === null}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

function BlotterFilter (props: {
  openModal: (content: React.JSX.Element) => void
  closeModal: () => void
  addFilter: (piece: BlotterFilterPiece) => void
  clearFilter: (type: BlotterFilterType) => void
  existingFilters: BlotterFilterPiece[]
  untaggedOnly: boolean
  setUntaggedOnly: (value: boolean) => void
  includeFees: boolean
  setIncludeFees: (value: boolean) => void
}): React.JSX.Element {
  const {
    openModal,
    closeModal,
    addFilter,
    clearFilter,
    existingFilters,
    untaggedOnly,
    setUntaggedOnly,
    includeFees,
    setIncludeFees
  } = props
  const authContext = useAuth()
  const { palette } = useTheme()

  return (
    <Stack direction={'column'} spacing={1} sx={{ width: '100%', height: '100%', maxHeight: '100%' }}>
      <Stack direction={'row'} spacing={1} sx={{ width: '100%', height: '50%', maxHeight: '50%' }}>
        <Button
          variant='contained'
          sx={{
            color: palette.accent.main,
            fontSize: 10,
            width: '100%'
          }}
          onClick={() => {
            openModal(<BaseFxFilterContent
              submitFunction={(piece: FilterPiece) => {
                addFilter({ piece, type: 'baseFx' })
                closeModal()
              }}
            />)
          }}
        >
          Add BaseFX Filter
        </Button>
        <Button
          variant='contained'
          sx={{
            color: palette.accent.main,
            fontSize: 10,
            width: '100%'
          }}
          onClick={() => {
            openModal(<InstrumentFilterContent
              submitFunction={(piece: FilterPiece) => {
                addFilter({ piece, type: 'instrument' })
                closeModal()
              }}
            />)
          }}
        >
          Add Instrument Filter
        </Button>
        <Button
          variant='contained'
          sx={{
            color: palette.accent.main,
            fontSize: 10,
            width: '100%'
          }}
          onClick={() => {
            openModal(<AccountFilterContent
              submitFunction={(piece: FilterPiece) => {
                addFilter({ piece, type: 'account' })
                closeModal()
              }}
            />)
          }}
        >
          Add Account Filter
        </Button>
        <Button
          variant='contained'
          sx={{
            color: palette.accent.main,
            fontSize: 10,
            width: '100%'
          }}
          onClick={() => {
            openModal(<StrategyFilterContent
              submitFunction={(piece: FilterPiece) => {
                addFilter({ piece, type: 'strategy' })
                closeModal()
              }}
            />)
          }}
        >
          Add Strategy Filter
        </Button>
        {
          authContext.restrictedDeskId === undefined && authContext.restrictedBlotterDeskId === undefined && <Button
            variant='contained'
            sx={{
              color: palette.accent.main,
              fontSize: 10,
              width: '100%'
            }}
            onClick={() => {
              openModal(<DeskFilterContent
                submitFunction={(piece: FilterPiece) => {
                  addFilter({ piece, type: 'desk' })
                  closeModal()
                }}
              />)
            }}
          >
            Add Desk Filter
          </Button>
        }
        <Box
          width={'100%'}
          alignContent={'center'}
        >
          <BooleanInput
            label='Untagged?'
            value={untaggedOnly}
            setValue={setUntaggedOnly}
            boxColor={palette.accent.main}
            textColor={palette.tableBodyText.main}
          />
        </Box>
        <Box
          width={'100%'}
          alignContent={'center'}
        >
          <BooleanInput
            label='Include Fees?'
            value={includeFees}
            setValue={setIncludeFees}
            boxColor={palette.accent.main}
            textColor={palette.tableBodyText.main}
          />
        </Box>
      </Stack>
      <Stack direction={'row'} spacing={1} sx={{ width: '100%', height: '50%' }}>
        {
          existingFilters.filter((filt) => filt.type === 'baseFx').length > 0 && <FilterDescription
            name='BaseFx'
            values={
              existingFilters.filter((filt) => filt.type === 'baseFx').map((val) => val.piece)}
            clearFunction={() => { clearFilter('baseFx') }}
          />
        }
        {
          existingFilters.filter((filt) => filt.type === 'instrument').length > 0 && <FilterDescription
            name='Instrument'
            values={
              existingFilters.filter((filt) => filt.type === 'instrument').map((val) => val.piece)}
            clearFunction={() => { clearFilter('instrument') }}
          />
        }
        {
          existingFilters.filter((filt) => filt.type === 'account').length > 0 && <FilterDescription
            name='Account'
            values={
              existingFilters.filter((filt) => filt.type === 'account').map((val) => val.piece)}
            clearFunction={() => { clearFilter('account') }}
          />
        }
        {
          existingFilters.filter((filt) => filt.type === 'strategy').length > 0 && <FilterDescription
            name='Strategy'
            values={
              existingFilters.filter((filt) => filt.type === 'strategy').map((val) => val.piece)}
            clearFunction={() => { clearFilter('strategy') }}
          />
        }
        {
          existingFilters.filter((filt) => filt.type === 'desk').length > 0 && <FilterDescription
            name='Desk'
            values={
              existingFilters.filter((filt) => filt.type === 'desk').map((val) => val.piece)}
            clearFunction={() => { clearFilter('desk') }}
          />
        }
      </Stack>
    </Stack>
  )
}

function BlotterControlsPanel (props: {
  viewType: BlotterOption
  setViewType: (type: BlotterOption) => void
  refreshFunction: () => void
  openModal: (content: React.JSX.Element) => void
  closeModal: () => void
  showTag: boolean
  tagRequest: (strategyId: string, deskId: string) => void
  time: Dayjs | null
  setTime: (value: Dayjs | null) => void
  addFilter: (piece: BlotterFilterPiece) => void
  clearFilter: (type: BlotterFilterType) => void
  existingFilters: BlotterFilterPiece[]
  untaggedOnly: boolean
  setUntaggedOnly: (value: boolean) => void
  includeFees: boolean
  setIncludeFees: (value: boolean) => void
}): React.JSX.Element {
  const {
    refreshFunction,
    openModal,
    closeModal,
    viewType,
    setViewType,
    showTag,
    tagRequest,
    time,
    setTime,
    addFilter,
    clearFilter,
    existingFilters,
    untaggedOnly,
    setUntaggedOnly,
    includeFees,
    setIncludeFees
  } = props
  const navigate = useNavigate()
  const { palette } = useTheme()
  const [timingOption, setTimingOption] = useState<TimingOption>(timingOptions[0])

  useEffect(() => {
    setTime(null)
  }, [timingOption])

  const styles = {
    dateTimePickerContainer: {
      height: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        height: '100%',
        width: '100%'
      },
      '& .MuiInputBase-root': {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }
    }
  }

  return (
    <Stack direction={'row'} spacing={1} sx={{ width: '100%', height: '100%' }}>
      <Stack direction={'column'} spacing={1} sx={{ width: '25%', height: '100%', maxHeight: '100%' }}>
        <Stack direction={'row'} spacing={1} sx={{ width: '100%', height: '50%', maxHeight: '50%' }}>
          <BlotterViewSelection
            currentType={viewType}
            typeChangeFunction={setViewType}
          />
          {
            viewType !== 'loans'
              ? <TimingSelection
                  timing={timingOption}
                  timingChangeFunction={setTimingOption}
                />
              : <Box sx={{ width: '100%' }}/>
          }
        </Stack>
        <Stack direction={'row'} spacing={1} sx={{ width: '100%', height: '50%' }}>
          {
            timingOption.type === TimingType.TIME
              ? <Box sx={styles.dateTimePickerContainer}>
                  <DateTimePicker
                    value={time}
                    onChange={setTime}
                    format='YYYY-MM-DD HH:mm'
                    closeOnSelect={false}
                    ampm={false}
                    timeSteps={{ hours: 1, minutes: 1 }}
                  />
                </Box>
              : <Box sx={{ width: '100%' }}/>
          }
        </Stack>
      </Stack>
      <Box sx={{ width: '58%', height: '100%' }}>
        {
          viewType === 'transactions' && <BlotterFilter
            openModal={openModal}
            closeModal={closeModal}
            addFilter={addFilter}
            clearFilter={clearFilter}
            existingFilters={existingFilters}
            untaggedOnly={untaggedOnly}
            setUntaggedOnly={setUntaggedOnly}
            includeFees={includeFees}
            setIncludeFees={setIncludeFees}
          />
        }
      </Box>
      <Stack
        direction={'row'}
        spacing={1}
        padding={0}
        alignSelf={'flex-end'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        sx={{ height: '50%', width: '17%' }}
      >
        {
          showTag && <Button
            variant='outlined'
            sx={{ color: palette.accent.main, height: '50%' }}
            onClick={() => {
              openModal(<TagContent
                closeModal={closeModal}
                submit={(strategy: LiteStrategy, desk: LiteDesk) => {
                  tagRequest(strategy.id, desk.id)
                }}
                prefillStrategyId={null}
              />)
            }}
          >
            Tag
          </Button>
        }
        <IconButton
          onClick={() => { navigate(`/blotter/${viewType}/new`) }}
          sx={{ height: '50%' }}
        >
          <AddIcon />
        </IconButton>
        <RefreshButton
          refreshFunction={refreshFunction}
          iconColor={palette.primary.main}
          buttonColor={palette.accent.main}
        />
      </Stack>
    </Stack>
  )
}

export default BlotterControlsPanel
