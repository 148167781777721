import React, { type Key, useEffect, useState, useMemo } from 'react'
import { type Dayjs } from 'dayjs'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import FunctionsIcon from '@mui/icons-material/Functions'
import {
  HeaderCell,
  type Order,
  RowSkeleton,
  ValueCell,
  getComparator,
  stableSort
} from '@r40cap/ui'
import { type PnlSnapshot, portfolioApi, type FundPnl } from '@r40cap/pms-sdk'

import { SuperStrategyRow } from './rows'
import { pnlColumns } from './constants'
import { type PnlRow } from './types'
import type { DeskOption } from '../common/types'
import { useAuth } from '@r40cap/auth'

interface PnlTableProps {
  onRequestSort: (property: keyof PnlRow) => void
  order: Order
  orderBy: keyof PnlRow
  openFirst: () => void
  openSecond: () => void
  closeAll: () => void
  firstOpenDesc: string
  secondOpenDesc: string
  isLoading: boolean
}

function PnlTableHead (props: PnlTableProps): React.JSX.Element {
  const { palette } = useTheme()
  const {
    onRequestSort,
    order,
    orderBy,
    openFirst,
    openSecond,
    closeAll,
    firstOpenDesc,
    secondOpenDesc,
    isLoading
  } = props
  const [clickTracker, setClickTracker] = useState<number>(0)
  const [buttonText, setButtonText] = useState<string>(firstOpenDesc)

  const createSortHandler = (property: keyof PnlRow): void => {
    onRequestSort(property)
  }

  function handleToggle (): void {
    if (clickTracker === 0) {
      openFirst()
      setClickTracker(1)
      setButtonText(secondOpenDesc)
    } else if (clickTracker === 1) {
      openSecond()
      setClickTracker(2)
      setButtonText('Close')
    } else {
      closeAll()
      setClickTracker(0)
      setButtonText(firstOpenDesc)
    }
  }

  return (
    <TableHead>
      <TableRow key={'header' as Key}>
        <TableCell
          sx={{ color: palette.tertiary.main }}
          key={'toggle'}
        >
          <Button
            variant='outlined'
            disabled={isLoading}
            onClick={handleToggle}
            sx={{
              color: palette.accent.main,
              fontSize: 9
            }}
          >
            {buttonText}
          </Button>
        </TableCell>
        {pnlColumns.map((column) => (
          <HeaderCell<PnlRow, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={createSortHandler}
            key={column.id as Key}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

function PnlTableFooter (props: { totalSnapshot?: PnlSnapshot, isLoading: boolean }): React.JSX.Element {
  const { totalSnapshot, isLoading } = props
  const { palette } = useTheme()

  if (isLoading || totalSnapshot === undefined) {
    return (
      <TableFooter sx={{ position: 'sticky', bottom: 0 }}>
        <RowSkeleton
          usedKey={-1}
          columns={pnlColumns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
        />
      </TableFooter>
    )
  } else {
    return (
      <TableFooter sx={{ position: 'sticky', bottom: 0 }}>
        <TableRow key='footer' sx={{ backgroundColor: palette.tertiary.main }}>
          <TableCell key={'toggle'}>
            <FunctionsIcon />
          </TableCell>
          {pnlColumns.map((column) => (
            <ValueCell<PnlRow, any>
              column={column}
              item={{
                title: 'Fund Total',
                day: totalSnapshot.day,
                week: totalSnapshot.week,
                month: totalSnapshot.month,
                year: totalSnapshot.year,
                inception: totalSnapshot.inception
              }}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
              key={column.id as Key}
            />
          ))}
        </TableRow>
      </TableFooter>
    )
  }
}

function PnlTableBody (props: {
  fundPnl?: FundPnl
  isLoading: boolean
  order: Order
  orderBy: keyof PnlRow
  superStrategiesForcedOpen: boolean
  strategiesForcedOpen: boolean
  forcedClosed: boolean
  invalidateForces: () => void
}): React.JSX.Element {
  const {
    fundPnl,
    isLoading,
    order,
    orderBy,
    superStrategiesForcedOpen,
    strategiesForcedOpen,
    forcedClosed,
    invalidateForces
  } = props

  const visibleSuperStrategies = useMemo(
    () => stableSort(fundPnl?.superStrategies ?? [], (a, b) => {
      const pnlRowA: PnlRow = {
        title: a.name,
        day: a.pnlSnapshot.day,
        week: a.pnlSnapshot.week,
        month: a.pnlSnapshot.month,
        year: a.pnlSnapshot.year,
        inception: a.pnlSnapshot.inception
      }
      const pnlRowB: PnlRow = {
        title: b.name,
        day: b.pnlSnapshot.day,
        week: b.pnlSnapshot.week,
        month: b.pnlSnapshot.month,
        year: b.pnlSnapshot.year,
        inception: b.pnlSnapshot.inception
      }
      return getComparator(order, orderBy)(pnlRowA, pnlRowB)
    }),
    [order, orderBy, fundPnl]
  )

  if (isLoading || fundPnl === undefined) {
    const rows = []
    for (let i = 0; i < 5; i++) {
      rows.push(
        <RowSkeleton
          usedKey={i}
          columns={pnlColumns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
          key={i}
        />
      )
    }
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  } else {
    const rows = visibleSuperStrategies.map((superStrategy) => {
      return (
        <SuperStrategyRow
          superStrategyPnl={superStrategy}
          key={superStrategy.name}
          order={order}
          orderBy={orderBy}
          superStrategiesForcedOpen={superStrategiesForcedOpen}
          strategiesForcedOpen={strategiesForcedOpen}
          forcedClosed={forcedClosed}
          invalidateForces={invalidateForces}
        />
      )
    })
    if (rows.length > 0) {
      return (
        <TableBody>
          {rows}
          <TableRow sx={{ height: '100%' }} />
        </TableBody>
      )
    } else {
      return (
        <TableBody>
          <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={pnlColumns.length + 1}
              align='center'
            >
              <Typography sx={{ fontSize: 20 }}>No Data</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
  }
}

function PnlTable (props: {
  time?: Dayjs
  includeUnsettled: boolean
  refreshSignal: boolean
  desk: DeskOption
}): React.JSX.Element {
  const { palette } = useTheme()
  const authConext = useAuth()
  const { time, includeUnsettled, refreshSignal, desk } = props

  const [superStrategiesForcedOpen, setSuperStrategiesForcedOpen] = useState<boolean>(false)
  const [strategiesForcedOpen, setStrategiesForcedOpen] = useState<boolean>(false)
  const [forcedClosed, setForcedClosed] = useState<boolean>(false)
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof PnlRow>('day')

  const { data, refetch, isFetching } = portfolioApi.useGetPnlQuery({
    time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
    includeUnsettled,
    desk: (desk.doFilter && authConext.restrictedDeskId === undefined) ? desk.id : undefined
  })

  function invalidateForces (): void {
    setForcedClosed(false)
    setSuperStrategiesForcedOpen(false)
    setStrategiesForcedOpen(false)
  }

  useEffect(() => {
    refetch().catch(() => { console.error('Error Refreshing') })
  }, [refreshSignal, refetch])

  const handleRequestSort = (property: keyof PnlRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        height: '100%',
        borderRadius: '5px'
      }}
    >
      <Table stickyHeader sx={{ height: '100%' }}>
        <PnlTableHead
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          isLoading={isFetching}
          closeAll={() => {
            setSuperStrategiesForcedOpen(false)
            setStrategiesForcedOpen(false)
            setForcedClosed(true)
          }}
          openFirst={() => {
            setSuperStrategiesForcedOpen(true)
            setStrategiesForcedOpen(false)
            setForcedClosed(false)
          }}
          openSecond={() => {
            setSuperStrategiesForcedOpen(true)
            setStrategiesForcedOpen(true)
            setForcedClosed(false)
          }}
          firstOpenDesc='Open Supers'
          secondOpenDesc='Open Strategies'
        />
        <PnlTableBody
          isLoading={isFetching}
          fundPnl={data?.data}
          order={order}
          orderBy={orderBy}
          superStrategiesForcedOpen={superStrategiesForcedOpen}
          strategiesForcedOpen={strategiesForcedOpen}
          forcedClosed={forcedClosed}
          invalidateForces={invalidateForces}
        />
        <PnlTableFooter
          isLoading={isFetching}
          totalSnapshot={data?.data.pnlSnapshot}
        />
      </Table>
    </TableContainer>
  )
}

export default PnlTable
