import React from 'react'
import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import type { BlotterInputType } from '../../common/types'
import { getBlotterEditModalContent } from '../../utils'

function CardDisplay<O> (props: {
  title: string
  value?: string | null
  backgroundColor: string
  titleTextColor: string
  valueTextColor: string
  editType: BlotterInputType
  openModal: (content: React.JSX.Element) => void
  closeModal: () => void
  editProperty?: keyof O
  onSubmission: (value: any) => void
  enabled: boolean
  isFetching: boolean
  copyable?: boolean
}): React.JSX.Element {
  const {
    title,
    value,
    backgroundColor,
    titleTextColor,
    valueTextColor,
    editType,
    openModal,
    closeModal,
    editProperty,
    onSubmission,
    enabled,
    isFetching
  } = props

  const copyableToUse = props.copyable ?? false

  function handleCopy (): void {
    if (value !== undefined && value !== null && copyableToUse) {
      navigator.clipboard.writeText(value)
        .catch((error) => {
          console.error('Failed to copy text: ', error)
        })
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '3px',
        backgroundColor,
        overflow: 'scroll'
      }}
    >
      <IconButton
        disabled={!enabled}
        disableFocusRipple
        disableRipple
        sx={{ width: '100%', height: '100%' }}
        onDoubleClick={() => {
          if (editProperty !== undefined) {
            openModal(
              getBlotterEditModalContent(
                editType,
                title,
                editProperty,
                closeModal,
                (property: keyof O, value: any, selectedIds: readonly string[]) => {
                  onSubmission(value)
                },
                [],
                (valud: string[]) => {},
                value
              )
            )
          }
        }}
      >
        <Stack direction='column' sx={{ width: '100%', height: '100%', padding: '5px' }}>
          <Typography fontSize={20} color={titleTextColor}>{title}</Typography>
          {
            isFetching
              ? <CircularProgress style={{ color: valueTextColor }}/>
              : copyableToUse && value !== undefined && value !== null
                ? <Stack direction={'row'} spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
                    <Typography sx={{ alignSelf: 'center' }} color={valueTextColor}>{value}</Typography>
                    <IconButton
                      onClick={handleCopy}
                      sx={{ alignSelf: 'center' }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Stack>
                : <Typography sx={{ alignSelf: 'center' }} color={valueTextColor}>{value ?? 'None'}</Typography>
          }
        </Stack>
      </IconButton>
    </Box>
  )
}

export default CardDisplay
