import React from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { type RiskComponent } from '@r40cap/pms-sdk'

function RiskComponentDisplay (props: {
  component: RiskComponent
}): React.JSX.Element {
  const { component } = props
  const { palette } = useTheme()

  return (
    <Stack
      direction={'row'}
      sx={{
        backgroundColor: palette.tertiary.main,
        width: '100%',
        height: '90px',
        borderRadius: '5px'
      }}
    >
      <Box
        sx={{ width: '40%', alignContent: 'center', padding: '10px' }}
      >
        <Typography
          sx={{ fontSize: 20, color: palette.tableBodyText.main }}
        >
          {component.description}:
        </Typography>
      </Box>
      <Box
        sx={{
          width: '60%',
          alignContent: 'center',
          padding: '10px'
        }}
      >
        <Typography
          align='right'
          sx={{ fontSize: 20, color: palette.tableBodyText.main, justifySelf: 'center' }}
        >
          {component.prefix ?? ''}{component.value.toLocaleString('en-US', {
            minimumFractionDigits: component.decimals ?? 2,
            maximumFractionDigits: component.decimals ?? 2
          })}{component.suffix ?? ''}
        </Typography>
      </Box>
    </Stack>
  )
}

export default RiskComponentDisplay
