import React from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { InstrumentInput, type LiteInstrument } from '@r40cap/pms-sdk'
import { NumberInput } from '@r40cap/ui'

export default function LegInput (props: {
  legName: string
  amountStr: string
  setAmountStr: (value: string) => void
  priceStr?: string
  setPriceStr?: (value: string) => void
  setInstrument: (value: LiteInstrument | null) => void
}): React.JSX.Element {
  const {
    legName,
    amountStr,
    setAmountStr,
    setInstrument,
    priceStr,
    setPriceStr
  } = props
  const { palette } = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: palette.primary.main,
        width: '100%',
        height: '100%',
        borderRadius: '5px'
      }}
    >
      <Stack spacing={1} padding={1}>
        <Typography color={palette.tableBodyText.main}>{legName}</Typography>
        <Stack direction='row' spacing={1}>
          <NumberInput
            title={`${legName} Amount`}
            intOnly={false}
            valueString={amountStr}
            setValueString={setAmountStr}
            color={palette.accent.main}
          />
          <InstrumentInput
            setInstrument={setInstrument}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            callColor={palette.success.main}
            putColor={palette.error.main}
            enhanced
            includeBaseFxFilter
          />
          {
            priceStr !== undefined && setPriceStr !== undefined && <NumberInput
              title={`${legName} Price`}
              intOnly={false}
              valueString={priceStr}
              setValueString={setPriceStr}
              color={palette.accent.main}
            />
          }
        </Stack>
      </Stack>
    </Box>
  )
}
