import { type ColumnDefinition } from '@r40cap/ui'

import { PnlTimeType, type PnlTimeOption, type PnlRow } from './types'

export const pnlTimingOptions: PnlTimeOption[] = [
  {
    label: 'Last Mark',
    type: PnlTimeType.LAST_MARK,
    value: 'last_mark'
  },
  {
    label: 'Specify EOD',
    type: PnlTimeType.EOD,
    value: 'eod'
  },
  {
    label: 'Live',
    type: PnlTimeType.CURRENT,
    value: 'live'
  }
]

export const pnlColumns: Array<ColumnDefinition<PnlRow, any>> = [
  {
    id: 'title',
    label: 'Name',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'day',
    label: 'Day',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'week',
    label: 'WTD',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'month',
    label: 'MTD',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'year',
    label: 'YTD',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'inception',
    label: 'ITD',
    columnWidthPercentage: 16,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  }
]
