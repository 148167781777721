import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Switch,
  useTheme
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { RefreshButton } from '@r40cap/ui'

import { type GlossaryOption } from './types'
import GlossarySelection from './GlossarySelection'
import PushButton from '../utils/PushButton'

function GlossaryControlsPanel (props: {
  currentType: GlossaryOption
  stuffToPush: boolean
  changeType: (type: GlossaryOption) => void
  refreshFunction: () => void
  pushFunction: () => void
  includeDeprecated: boolean
  setIncludeDeprecated: (value: boolean) => void
  showBaseTables: boolean
  setShowBaseTables: (value: boolean) => void
}): React.JSX.Element {
  const {
    currentType,
    changeType,
    stuffToPush,
    refreshFunction,
    pushFunction,
    includeDeprecated,
    setIncludeDeprecated,
    showBaseTables,
    setShowBaseTables
  } = props
  const { palette } = useTheme()
  const navigate = useNavigate()

  return (
    <Grid container sx={{ height: '6vh' }}>
      <Grid item xs={6}>
        <Stack direction={'row'} alignItems={'center'} spacing={3}>
          <GlossarySelection
            currentType={currentType}
            typeChangeFunction={changeType}
            showBaseTables={showBaseTables}
            setShowBaseTables={setShowBaseTables}
          />
          <FormGroup>
            <FormControlLabel
              control={<Switch
                sx={{
                  '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: palette.accent.main,
                    opacity: 0.9
                  },
                  '& .MuiSwitch-thumb': {
                    color: palette.primary.main
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: palette.tableBodyText.main
                  }
                }}
                color='default'
                checked={includeDeprecated}
                onChange={() => { setIncludeDeprecated(!includeDeprecated) }}
              />}
              label='Include Deprecated?'
              sx={{ color: palette.tableBodyText.main }}
            />
          </FormGroup>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'center'}
      >
        <Grid
          item
          xs={6}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignContent={'center'}
            sx={{ height: '50%' }}
          >
            <IconButton
              onClick={() => { navigate(`/glossary/${currentType}/new`) }}
            >
              <AddIcon />
            </IconButton>
            { stuffToPush && <PushButton
              pushFunction={pushFunction}
            /> }
            <RefreshButton
              refreshFunction={refreshFunction}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GlossaryControlsPanel
