import React from 'react'
import { Button, Typography, useTheme } from '@mui/material'

function PushButton (props: {
  pushFunction: () => void
}): React.JSX.Element {
  const { pushFunction } = props
  const { palette } = useTheme()

  return (
    <Button
      variant="outlined"
      sx={{ color: palette.accent.main }}
      onClick={pushFunction}
    >
      <Typography textTransform="capitalize">Push</Typography>
    </Button>
  )
}

export default PushButton
