import type { ColumnDefinition } from '@r40cap/ui'
import type {
  CapitalCall,
  Expense,
  Loan,
  Transaction
} from '@r40cap/pms-sdk'

import {
  BlotterInputType,
  type CapitalCallRow,
  type ExpenseRow,
  type TransactionRow,
  type LoanRow
} from '../common/types'

export const ROWS_PER_PAGE = 20

export const transactionColumns: Array<ColumnDefinition<TransactionRow, BlotterInputType, Transaction>> = [
  {
    id: 'time',
    label: 'Time',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'trader',
    label: 'Trader',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'legOne',
    label: 'Leg One',
    columnWidthPercentage: 37,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'legTwo',
    label: 'Leg Two',
    columnWidthPercentage: 37,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]

export const capitalCallColumns: Array<ColumnDefinition<CapitalCallRow, BlotterInputType, CapitalCall>> = [
  {
    id: 'time',
    label: 'Time',
    columnWidthPercentage: 20,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'investor',
    label: 'Investor',
    columnWidthPercentage: 11,
    inputType: BlotterInputType.INVESTOR,
    editId: 'investor',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'memo',
    label: 'Memo',
    columnWidthPercentage: 60,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRING,
    editId: 'memo',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]

export const expenseColumns: Array<ColumnDefinition<ExpenseRow, BlotterInputType, Expense>> = [
  {
    id: 'time',
    label: 'Time',
    columnWidthPercentage: 13,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'vendor',
    label: 'Vendor',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: BlotterInputType.ENTITY,
    editId: 'vendor',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'reason',
    label: 'Reason',
    columnWidthPercentage: 50,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRING,
    editId: 'reason',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]

export const loanColumns: Array<ColumnDefinition<LoanRow, BlotterInputType, Loan>> = [
  {
    id: 'counterparty',
    label: 'Counterparty',
    columnWidthPercentage: 14,
    inputType: BlotterInputType.ENTITY,
    editId: 'counterparty',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'expiration',
    label: 'Expiration',
    columnWidthPercentage: 11,
    overflowType: 'scroll',
    inputType: BlotterInputType.TIME,
    editId: 'expiration',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]
