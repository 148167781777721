import type { ColumnDefinition } from '@r40cap/ui'
import type { ReconciliationLog } from '@r40cap/pms-sdk'

import { HistoryInputType, type HistoryRow } from './types'

export const historyColumns: Array<ColumnDefinition<HistoryRow, HistoryInputType, ReconciliationLog>> = [
  {
    id: 'user',
    label: 'User',
    columnWidthPercentage: 5,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'entryTime',
    label: 'Entry Time',
    columnWidthPercentage: 8,
    inputType: HistoryInputType.TIME,
    editId: 'entryTime',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'systemPositionsTime',
    label: 'Sys Time',
    columnWidthPercentage: 8,
    inputType: HistoryInputType.TIME,
    editId: 'systemPositionsTime',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'account',
    label: 'Platform',
    columnWidthPercentage: 10,
    inputType: HistoryInputType.ACCOUNT,
    editId: 'account',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 8,
    inputType: HistoryInputType.INSTRUMENT,
    editId: 'instrument',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'systemBalance',
    label: 'System',
    columnWidthPercentage: 9,
    inputType: HistoryInputType.NUMBER,
    editId: 'systemBalance',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'balanceDecimals'
  },
  {
    id: 'platformBalance',
    label: 'Platform',
    columnWidthPercentage: 9,
    inputType: HistoryInputType.NUMBER,
    editId: 'platformBalance',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'balanceDecimals'
  },
  {
    id: 'platformErrorExplanation',
    label: 'Error Reason',
    columnWidthPercentage: 9,
    inputType: HistoryInputType.TEXT,
    editId: 'platformErrorExplanation',
    overflowType: 'tooltip',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'adminBalance',
    label: 'NAV',
    columnWidthPercentage: 9,
    inputType: HistoryInputType.NUMBER,
    editId: 'adminBalance',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'balanceDecimals'
  },
  {
    id: 'adminErrorExplanation',
    label: 'NAV Error Reason',
    columnWidthPercentage: 9,
    inputType: HistoryInputType.TEXT,
    editId: 'adminErrorExplanation',
    overflowType: 'tooltip',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]
