import { type DeskOption } from './types'

export const deskOptions: DeskOption[] = [
  {
    id: 'a4c29c1f-7c91-4258-ab1c-652c1089078d',
    label: 'Global',
    doFilter: true
  },
  {
    id: '209b1408-beab-4d3a-b7ef-476040d7d6ff',
    label: 'BD',
    doFilter: true
  },
  {
    label: 'All',
    id: 'none',
    doFilter: false
  }
]
