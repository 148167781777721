import React, { useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, Button, Stack, useTheme } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { BooleanInput, ColorInput, StringInput, NumberInput } from '@r40cap/ui'
import {
  type LiteBaseFx,
  type LiteEntity,
  type LiteInstrumentType,
  type LitePricingMethod,
  type LiteSuperStrategy,
  type SettlementInstrument,
  BaseFxInput,
  EntityInput,
  InstrumentTypeInput,
  PricingMethodInput,
  SuperStrategyInput,
  InstrumentInput,
  type LiteInstrument,
  type LiteDesk,
  DeskInput
} from '@r40cap/pms-sdk'

export function NumberEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: number) => void
}): React.JSX.Element {
  const { title, submit, closeModal } = props
  const [valueString, setValueString] = useState<string>('')
  const { palette } = useTheme()

  const handleEnterSubmission = (value: number): void => {
    submit(value)
    closeModal()
  }

  const handleSubmission = (): void => {
    if (valueString !== '') {
      const result = parseFloat(valueString)
      submit(result)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <NumberInput
          title={title}
          intOnly={false}
          valueString={valueString}
          setValueString={setValueString}
          submit={handleEnterSubmission}
          defaultValue={0}
          defaultPrecision={1}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function IntegerEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: number) => void
}): React.JSX.Element {
  const { palette } = useTheme()
  const { title, submit, closeModal } = props
  const [valueString, setValueString] = useState<string>('')

  const handleEnterSubmission = (value: number): void => {
    submit(value)
    closeModal()
  }

  const handleSubmission = (): void => {
    if (valueString !== '') {
      const result = parseInt(valueString)
      submit(result)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <NumberInput
          title={title}
          intOnly={true}
          valueString={valueString}
          setValueString={setValueString}
          submit={handleEnterSubmission}
          defaultValue={0}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function StringEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: string) => void
}): React.JSX.Element {
  const { title, submit, closeModal } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<string>('')

  function handleSubmit (): void {
    submit(value)
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <StringInput
          title={title}
          value={value}
          setValue={setValue}
          enterSubmit={handleSubmit}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={handleSubmit}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function BooleanEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: boolean) => void
}): React.JSX.Element {
  const { title, closeModal, submit } = props
  const { palette } = useTheme()

  const [value, setValue] = useState<boolean>(false)

  const handleSubmission = (): void => {
    submit(value)
    closeModal()
  }

  return (
    <Box
      sx={{ width: '15vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <BooleanInput
          setValue={setValue}
          value={value}
          label={title}
          boxColor={palette.accent.main}
          textColor={palette.background.default}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function SettlementInstrumentEditContent (props: {
  title: string
  closeModal: () => void
  submit: (value: SettlementInstrument | null) => void
}): React.JSX.Element {
  const { title, closeModal, submit } = props
  const { palette } = useTheme()

  const [instrument, setInstrument] = useState<LiteInstrument | null>(null)

  const handleSubmission = (): void => {
    if (instrument !== null) {
      submit({
        id: instrument.id,
        displayTicker: instrument.displayTicker
      })
    } else {
      submit(null)
    }
    closeModal()
  }

  return (
    <Box
      sx={{ width: '15vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <InstrumentInput
          setInstrument={setInstrument}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          callColor='green'
          putColor='red'
          enhanced={false}
          includeBaseFxFilter={false}
          includeInstrumentTypeFilter={false}
          labelOverride={title}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function DateTimeEditContent (props: {
  closeModal: () => void
  submit: (value: string) => void
}): React.JSX.Element {
  dayjs.extend(utc)
  const { closeModal, submit } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<Dayjs | null>(dayjs().utc())

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value.format('YYYY-MM-DD HH:mm'))
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <DateTimePicker
          value={value}
          format='YYYY-MM-DD HH:mm'
          onChange={setValue}
          closeOnSelect={false}
          ampm={false}
          timeSteps={{ hours: 1, minutes: 1 }}
          sx={{ width: '100%' }}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function SuperStrategyEditContent (props: {
  closeModal: () => void
  submit: (value: LiteSuperStrategy) => void
  prefillSuperStrategyId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillSuperStrategyId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteSuperStrategy | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '20vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <SuperStrategyInput
          setSuperStrategy={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          prefillSuperStrategyId={prefillSuperStrategyId}
          enhanced
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function EntityEditContent (props: {
  closeModal: () => void
  submit: (value: LiteEntity | null) => void
  prefillEntityId?: string | null
  allowNull?: boolean
}): React.JSX.Element {
  const { closeModal, submit, prefillEntityId, allowNull } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteEntity | null>(null)
  const useAllowNull = allowNull ?? false

  const handleSubmission = (): void => {
    if (value !== null || useAllowNull) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '20vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={4}>
        <EntityInput
          setEntity={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced={true}
          prefillEntityId={prefillEntityId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function PricingMethodEditContent (props: {
  closeModal: () => void
  submit: (value: LitePricingMethod) => void
  prefillPricingMethodId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillPricingMethodId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LitePricingMethod | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '20vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={4}>
        <PricingMethodInput
          setPricingMethod={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced={true}
          prefillPricingMethodId={prefillPricingMethodId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function BaseFxEditContent (props: {
  closeModal: () => void
  submit: (value: LiteBaseFx) => void
  prefillBaseFxId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillBaseFxId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteBaseFx | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={4}>
        <BaseFxInput
          setBaseFx={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced={true}
          prefillBaseFxId={prefillBaseFxId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function DeskxEditContent (props: {
  closeModal: () => void
  submit: (value: LiteDesk) => void
  prefillDeskId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillDeskId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteDesk | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={4}>
        <DeskInput
          setDesk={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced={true}
          prefillDeskId={prefillDeskId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

export function InstrumentTypeEditContent (props: {
  closeModal: () => void
  submit: (value: LiteInstrumentType) => void
  prefillInstrumentTypeId?: string | null
}): React.JSX.Element {
  const { closeModal, submit, prefillInstrumentTypeId } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<LiteInstrumentType | null>(null)

  const handleSubmission = (): void => {
    if (value !== null) {
      submit(value)
      closeModal()
    }
  }

  return (
    <Box
      sx={{ width: '20vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={4}>
        <InstrumentTypeInput
          setInstrumentType={setValue}
          setNone={(dummy: boolean) => {}}
          allowNone={false}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          enhanced={true}
          prefillInstrumentTypeId={prefillInstrumentTypeId}
        />
        <Button
          variant='contained'
          onClick={handleSubmission}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}

function getRandomColor (): string {
  const characters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += characters[Math.floor(Math.random() * 16)]
  }
  return color
}

export function ColorEditContent (props: {
  title: string
  prefillValue?: string
  closeModal: () => void
  submit: (value: string) => void
}): React.JSX.Element {
  const { title, submit, closeModal, prefillValue } = props
  const { palette } = useTheme()
  const [value, setValue] = useState<string>(prefillValue ?? getRandomColor())

  function handleSubmit (): void {
    submit(value)
    closeModal()
  }

  return (
    <Box
      sx={{ width: '17vw' }}
    >
      <Stack direction={'column'} spacing={2} padding={3}>
        <ColorInput
          label={title}
          spacing={2}
          value={value}
          setValue={setValue}
          buttonOutlineColor={palette.secondary.main}
          submitButtonColor={palette.accent.main}
          textColor={palette.background.default}
        />
        <Button
          variant='contained'
          onClick={handleSubmit}
          sx={{
            width: '50%',
            alignSelf: 'center',
            backgroundColor: palette.accent.main,
            color: palette.background.default
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}
