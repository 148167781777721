import { type PaletteMode, type ThemeOptions } from '@mui/material'

import { darkPalette, lightPalette } from './palettes'

declare module '@mui/material/styles/createPalette' {
  interface GradientPaletteOptions {
    primary?: string
    secondary?: string
  }
  interface GradientPalette {
    primary: string
    secondary: string
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary']
    accent?: PaletteOptions['primary']
    gradient?: GradientPaletteOptions
    themeToggleBackground?: PaletteOptions['primary']
    themeToggleIcon?: PaletteOptions['primary']
    tableHeaderText?: PaletteOptions['primary']
    tableBodyText?: PaletteOptions['primary']
    staleColor?: PaletteOptions['primary']
    logoColor?: PaletteOptions['primary']
  }
  interface Palette {
    tertiary: Palette['primary']
    accent: Palette['primary']
    gradient: GradientPalette
    themeToggleBackground: Palette['primary']
    themeToggleIcon: Palette['primary']
    tableHeaderText: Palette['primary']
    tableBodyText: Palette['primary']
    staleColor: Palette['primary']
    logoColor: Palette['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true
    themeToggleBackground: true
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    accent: true
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    accent: true
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    accent: true
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    accent: true
  }
}

declare module '@mui/material/InputLabel' {
  interface InputLabelPropsColorOverrides {
    accent: true
  }
}

declare module '@mui/material/FormLabel' {
  interface FormLabelPropsColorOverrides {
    accent: true
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsColorOverrides {
    accent: true
  }
}

const themeOptions = (mode: PaletteMode): ThemeOptions => {
  switch (mode) {
    case 'light':
      return {
        spacing: 10,
        palette: {
          mode,
          ...lightPalette
        }
      }
    case 'dark':
      return {
        spacing: 10,
        palette: {
          mode,
          ...darkPalette
        }
      }
  }
}

export default themeOptions
