import React, { useEffect, useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, Stack } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { type LiteAccount, type PlatformSnapshot, type ReconciliationLogSubmission, reconciliationApi } from '@r40cap/pms-sdk'

import UpdatingControlsPanel from './UpdatingControlsPanel'
import type { UpdatingRow } from './types'
import type { ReconciliationOption } from '../types'
import NoAccountChosen from '../../utils/NoAccountChosen'
import LiveViewTable from './LiveViewTable'
import SnapshotViewTable from './SnapshotViewTable'

function UpdatingView (props: {
  viewType: ReconciliationOption
  setViewType: (value: ReconciliationOption) => void
}): React.JSX.Element {
  dayjs.extend(utc)
  const {
    viewType,
    setViewType
  } = props
  const { showSnackbar } = useRequestSnackbar()
  const [account, setAccount] = useState<LiteAccount | null>(null)
  const [snapshot, setSnapshot] = useState<PlatformSnapshot | null>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<React.JSX.Element | null>(null)
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const [matchedMap, setMatchedMap] = useState<Map<string, UpdatingRow>>(new Map<string, UpdatingRow>())
  const [selectedIds, setSelectedIds] = useState<readonly string[]>([])
  const [liveSystemTimeOverride, setLiveSystemTimeOverride] = useState<Dayjs | null>(null)
  const [snapshotSystemTimeOverride, setSnapshotSystemTimeOverride] = useState<Dayjs | null>(null)
  const [snapshotClearSignal, setSnapshotClearSignal] = useState<boolean>(false)

  const [logEntries] = reconciliationApi.useLogReconciliationEntriesMutation()

  function openModal (content: React.JSX.Element): void {
    setModalContent(content)
    setModalOpen(true)
  }

  function closeModal (): void {
    setModalContent(null)
    setModalOpen(false)
  }

  useEffect(() => {
    setSnapshot(null)
    setSnapshotSystemTimeOverride(null)
    setSnapshotClearSignal(!snapshotClearSignal)
  }, [account?.id])

  useEffect(() => {
    setSnapshotSystemTimeOverride(null)
  }, [snapshot?.id])

  function pushLogs (): void {
    if (account !== null) {
      const logsArray = Array.from(matchedMap.values()).filter((log) => selectedIds.includes(log.instrumentId))
      if (logsArray.length > 0) {
        showSnackbar({
          isOpen: true,
          message: 'Pushing Logs',
          status: 'processing'
        })
        const now = dayjs().utc()
        logEntries({
          additions: logsArray.map((log) => {
            const submission: ReconciliationLogSubmission = {
              id: '',
              entryTime: now.format('YYYY-MM-DD HH:mm'),
              systemPositionsTime: snapshot === null
                ? liveSystemTimeOverride === null
                  ? now.format('YYYY-MM-DD HH:mm')
                  : liveSystemTimeOverride.format('YYYY-MM-DD HH:mm')
                : snapshotSystemTimeOverride === null
                  ? snapshot.snapshotTime
                  : snapshotSystemTimeOverride.format('YYYY-MM-DD HH:mm'),
              account: account.id,
              instrument: log.instrumentId,
              systemBalance: log.ourBalance,
              platformBalance: log.platformBalance,
              platformErrorExplanation: log.platformErrorExplanation,
              adminBalance: log.adminBalance,
              adminErrorExplanation: log.adminErrorExplanation
            }
            return submission
          })
        })
          .then((value) => {
            showSnackbar({
              isOpen: true,
              message: 'Logs Pushed',
              status: 'success'
            })
          })
          .catch((error) => {
            console.error(error)
            showSnackbar({
              isOpen: true,
              message: 'Failed to push logs',
              status: 'error'
            })
          })
      }
    }
    setSelectedIds([])
  }

  return (
    <>
      <Stack
        spacing={1}
        direction={'column'}
        width={'100%'}
        sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
      >
        <Box sx={{ height: '7%' }}>
          <UpdatingControlsPanel
            refreshFunction={() => { setRefreshSignal(!(refreshSignal as boolean)) }}
            showLogButton={selectedIds.length > 0}
            logFunction={pushLogs}
            viewType={viewType}
            setViewType={setViewType}
            setAccount={setAccount}
            account={account}
            snapshot={snapshot}
            setSnapshot={setSnapshot}
            liveSystemTimeOverride={liveSystemTimeOverride}
            setLiveSystemTimeOverride={setLiveSystemTimeOverride}
            snapshotSystemTimeOverride={snapshotSystemTimeOverride}
            setSnapshotSystemTimeOverride={setSnapshotSystemTimeOverride}
            snapshotClearSignal={snapshotClearSignal}
          />
        </Box>
        <Box sx={{ height: '93%' }}>
          {
            account === null
              ? <NoAccountChosen />
              : snapshot === null
                ? <LiveViewTable
                    refreshSignal={refreshSignal}
                    accountId={account.id}
                    openModal={openModal}
                    closeModal={closeModal}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    setMap={setMatchedMap}
                    map={matchedMap}
                    systemTimeOverride={liveSystemTimeOverride}
                  />
                : <SnapshotViewTable
                      refreshSignal={refreshSignal}
                      snapshot={snapshot}
                      openModal={openModal}
                      closeModal={closeModal}
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      setMap={setMatchedMap}
                      map={matchedMap}
                      systemTimeOverride={snapshotSystemTimeOverride}
                    />
          }
        </Box>
      </Stack>
      <Modal
        open={modalOpen}
        handleClose={closeModal}
      >
        {modalContent ?? <h1>Empty</h1>}
      </Modal>
    </>
  )
}

export default UpdatingView
