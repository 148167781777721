import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { type PricingMethod, PricingMethodCreator, glossaryApi } from '@r40cap/pms-sdk'

function AddPricingMethodModal (): React.JSX.Element {
  const navigate = useNavigate()
  const { palette } = useTheme()
  const { showSnackbar } = useRequestSnackbar()
  const [postAdditions] = glossaryApi.useAddPricingMethodsMutation()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/glossary/pricingMethods')
    }
  }

  function addPricingMethod (pricingMethod: PricingMethod): void {
    showSnackbar({
      isOpen: true,
      message: 'Creating Pricing Method',
      status: 'processing'
    })
    postAdditions({
      additions: [pricingMethod]
    })
      .then((value) => {
        showSnackbar({
          isOpen: true,
          message: 'Created Pricing Method',
          status: 'success'
        })
        exitModal()
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to create Pricing Method',
          status: 'error'
        })
      })
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: '15vw',
          padding: '20px'
        }}
      >
        <PricingMethodCreator
          submitFunction={addPricingMethod}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          spacing={2}
        />
      </Box>
    </Modal>
  )
}

export default AddPricingMethodModal
