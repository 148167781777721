import React from 'react'
import { Stack, Typography, useTheme } from '@mui/material'

function NoAccountChosen (): React.JSX.Element {
  const { palette } = useTheme()

  return (
    <Stack
      direction={'column'}
      sx={{
        height: '75vh',
        width: '100%',
        justifyContent: 'center'
      }}
    >
      <Typography
        sx={{
          color: palette.logoColor.main,
          alignSelf: 'center',
          fontSize: 40
        }}
      >
        Select an Account
      </Typography>
    </Stack>
  )
}

export default NoAccountChosen
