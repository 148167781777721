import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs, { type Dayjs } from 'dayjs'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { blotterApi } from '@r40cap/pms-sdk'

function SettleTransactionModal (): React.JSX.Element {
  const { transactionId } = useParams()
  const { palette } = useTheme()
  const navigate = useNavigate()
  const { showSnackbar } = useRequestSnackbar()
  const [settlementTime, setSettlementTime] = useState<Dayjs>(dayjs().utc())

  const [settleTransaction] = blotterApi.useSettleTransactionMutation()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/blotter/unsettled')
    }
  }

  function submitSettlement (): void {
    showSnackbar({
      isOpen: true,
      message: 'Settling Transaction',
      status: 'processing'
    })
    settleTransaction({
      txnId: transactionId ?? '',
      time: settlementTime.format('YYYY-MM-DD HH:mm')
    })
      .unwrap()
      .then((value) => {
        showSnackbar({
          isOpen: true,
          message: 'Transaction Settled',
          status: 'success'
        })
        exitModal()
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to Post Loan',
          status: 'error'
        })
      })
  }

  const styles = {
    dateTimePickerContainer: {
      height: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        height: '100%',
        width: '100%'
      },
      '& .MuiInputBase-root': {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }
    }
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box sx={{ width: '25vw' }}>
        <Stack direction={'column'} spacing={2} padding={3}>
          <Typography sx={{ color: palette.tableBodyText.main }}>Settlement Time</Typography>
          <Box sx={styles.dateTimePickerContainer}>
            <DateTimePicker
              value={settlementTime}
              onChange={(val) => {
                if (val !== null) {
                  setSettlementTime(val)
                }
              }}
              format='YYYY-MM-DD HH:mm'
              closeOnSelect={false}
              ampm={false}
              timeSteps={{ hours: 1, minutes: 1 }}
            />
          </Box>
          <Button
            onClick={submitSettlement}
            variant='contained'
            style={{
              width: '100%',
              backgroundColor: palette.accent.main,
              color: palette.secondary.main
            }}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default SettleTransactionModal
