import { type TransactionSummaryLeg } from '@r40cap/pms-sdk'

function getLegDescription (leg: TransactionSummaryLeg): string {
  const strategyStr = (
    leg.strategyName === undefined ||
    leg.strategyName === null ||
    leg.superStrategyName === null
  )
    ? '(Untagged)'
    : `${leg.superStrategyName} - ${leg.strategyName}`
  const qtyStr = Math.abs(leg.quantity).toLocaleString('en-US', {
    minimumFractionDigits: leg.quantityDecimals,
    maximumFractionDigits: leg.quantityDecimals
  })
  if (leg.isFee as boolean) {
    return `Fee: ${qtyStr} ${leg.instrumentDisplay} ${strategyStr} ${leg.platformName} - ${leg.accountName}`
  } else {
    const sideStr = leg.quantity >= 0 ? 'BUY\u00A0\u00A0' : 'SELL\u00A0'
    const priceStr = Math.abs(leg.price).toLocaleString('en-US', {
      minimumFractionDigits: leg.priceDecimals,
      maximumFractionDigits: leg.priceDecimals
    })
    return `${sideStr} ${qtyStr} ${leg.instrumentDisplay} @ $${priceStr} ${strategyStr} ${leg.platformName} - ${leg.accountName}`
  }
}

interface Legs {
  legOne?: string
  legTwo?: string
}

function getFinalLegs (filteredSummaryLegs: TransactionSummaryLeg[]): Legs {
  if (filteredSummaryLegs.length === 0) {
    return {
      legOne: undefined,
      legTwo: undefined
    }
  } else if (filteredSummaryLegs.length === 1) {
    return {
      legOne: getLegDescription(filteredSummaryLegs[0]),
      legTwo: undefined
    }
  } else {
    return {
      legOne: getLegDescription(filteredSummaryLegs[0]),
      legTwo: getLegDescription(filteredSummaryLegs[1])
    }
  }
}

export function getTransactionLegs (summaryLegs: TransactionSummaryLeg[]): Legs {
  if (summaryLegs.length === 0) {
    return {
      legOne: undefined,
      legTwo: undefined
    }
  } else {
    const nonFeeLegs = summaryLegs.filter((leg) => !(leg.isFee as boolean))
    if (nonFeeLegs.length === 0) {
      const nonCashLegs = summaryLegs.filter((leg) => !(leg.isCash as boolean))
      if (nonCashLegs.length === 0) {
        return getFinalLegs(summaryLegs)
      } else {
        return getFinalLegs(nonCashLegs)
      }
    } else {
      const nonCashLegs = nonFeeLegs.filter((leg) => !(leg.isCash as boolean))
      if (nonCashLegs.length === 0) {
        return getFinalLegs(nonFeeLegs)
      } else {
        return getFinalLegs(nonCashLegs)
      }
    }
  }
}
