import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, useTheme } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { type Entity, type Expense, blotterApi } from '@r40cap/pms-sdk'

import CardDisplay from './CardDisplay'
import { BlotterInputType } from '../../common/types'
import PushButton from '../../../utils/PushButton'

function ExpensePanel (props: {
  expense: Expense | null
  isFetching: boolean
  resetSignal: boolean
}): React.JSX.Element {
  const { expense, isFetching, resetSignal } = props
  const { palette } = useTheme()
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<React.JSX.Element>(<></>)
  const [editedExpense, setEditedExpense] = useState<Expense | null>(expense)
  const { showSnackbar } = useRequestSnackbar()

  useEffect(() => {
    setEditedExpense(expense)
  }, [expense, resetSignal])

  const [pushEditedExpense] = blotterApi.useEditExpenseByIdMutation()

  function setVendor (value: Entity): void {
    if (editedExpense !== null) {
      setEditedExpense({
        ...editedExpense,
        vendor: value
      })
    }
  }

  function setReason (value: string): void {
    if (editedExpense !== null) {
      setEditedExpense({
        ...editedExpense,
        reason: value
      })
    }
  }

  function handlePush (): void {
    if (editedExpense !== null) {
      showSnackbar({
        isOpen: true,
        message: 'Editing Expense',
        status: 'processing'
      })
      pushEditedExpense({
        edit: {
          id: editedExpense.id,
          vendor: editedExpense.vendor,
          reason: editedExpense.reason
        }
      })
        .then((value) => {
          showSnackbar({
            isOpen: true,
            message: 'Edit Complete',
            status: 'success'
          })
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to edit Expense',
            status: 'error'
          })
        })
    }
  }

  return (
    <>
      <Stack direction={'column'} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }} textAlign={'right'}>
          {
            editedExpense !== expense && <PushButton
              pushFunction={handlePush}
            />
          }
        </Box>
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={4} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<Expense>
              title={'Vendor'}
              value={editedExpense?.vendor.name}
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.ENTITY}
              openModal={(content: React.JSX.Element) => {
                setModalContent(content)
                setEditModalOpen(true)
              }}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              editProperty='vendor'
              onSubmission={setVendor}
              enabled
              isFetching={isFetching}
            />
          </Grid>
          <Grid item xs={8} sx={{ padding: '5px', height: '90px' }}>
            <CardDisplay<Expense>
              title={'Reason'}
              value={editedExpense?.reason}
              backgroundColor={palette.primary.main}
              titleTextColor={palette.tableBodyText.main}
              valueTextColor={palette.tableBodyText.main}
              editType={BlotterInputType.STRING}
              openModal={(content: React.JSX.Element) => {
                setModalContent(content)
                setEditModalOpen(true)
              }}
              closeModal={() => {
                setModalContent(<></>)
                setEditModalOpen(false)
              }}
              editProperty='reason'
              onSubmission={setReason}
              enabled
              isFetching={isFetching}
            />
          </Grid>
        </Grid>
      </Stack>
      <Modal
        open={editModalOpen}
        handleClose={() => {
          setModalContent(<></>)
          setEditModalOpen(false)
        }}
      >
        {modalContent}
      </Modal>
    </>
  )
}

export default ExpensePanel
