import React from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  useTheme
} from '@mui/material'

import { type BaseFxOption } from './types'
import { baseFxOptions } from './constants'

function BaseFxSelection (props: {
  currentBase: BaseFxOption
  baseChange: (view: BaseFxOption) => void
}): React.JSX.Element {
  const { currentBase, baseChange } = props
  const { palette } = useTheme()

  const handleBaseChange = (event: SelectChangeEvent): void => {
    const chosenOption = baseFxOptions.find((option) => option.id === event.target.value)
    if (chosenOption !== undefined) {
      baseChange(chosenOption)
    }
  }

  return (
    <FormControl>
      <InputLabel
        sx={{ color: palette.tableBodyText.main }}
      >
        BaseFX
      </InputLabel>
      <Select
        value={currentBase.id}
        onChange={handleBaseChange}
        sx={{ width: '100%', height: '100%' }}
        color="accent"
      >
        {baseFxOptions.map(
          (option, index) => (
            <MenuItem key={index} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default BaseFxSelection
