import type { ColumnDefinition } from '@r40cap/ui'
import type {
  TransactionAssetChange,
  TransactionLiability
} from '@r40cap/pms-sdk'

import {
  type AddAssetChangeRow,
  type AddLiabilityRow,
  type ExpenseAdditionSelectOption,
  type CapitalCallAdditionSelectOption,
  BlotterInputType
} from './types'

export const expenseSelectOptions: ExpenseAdditionSelectOption[] = [
  {
    type: 'basicExpense',
    display: 'Basic Expense',
    width: 80
  },
  {
    type: 'custom',
    display: 'Custom',
    width: 90
  }
]

export const capitalCallSelectOptions: CapitalCallAdditionSelectOption[] = [
  {
    type: 'basicCapitalCall',
    display: 'Basic Capital Call',
    width: 90
  },
  {
    type: 'custom',
    display: 'Custom',
    width: 90
  }
]

export const transactionAssetChangeColumns: Array<ColumnDefinition<AddAssetChangeRow, BlotterInputType, TransactionAssetChange>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 7,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 14,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'time',
    label: 'Time',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: BlotterInputType.TIME,
    editId: 'time',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'settledTime',
    label: 'Settled Time',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: BlotterInputType.TIME,
    editId: 'settledTime',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 12,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'isFee',
    label: 'Fee?',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    inputType: BlotterInputType.BOOLEAN,
    editId: 'isFee',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    }
  }
]

export const transactionLiabilityColumns: Array<ColumnDefinition<AddLiabilityRow, BlotterInputType, TransactionLiability>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    reverseColor: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'interestRate',
    label: 'Rate',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'interestRate',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'topUpLevel',
    label: 'Top Up',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'topUpLevel',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]
