import React from 'react'
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Switch,
  useTheme
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { DateTimePicker } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import { RefreshButton } from '@r40cap/ui'

import PushButton from '../utils/PushButton'
import { getLastMark } from '../../utils/times'

function PricingControlsPanel (props: {
  currentTime: Dayjs | null
  setTime: (day: Dayjs | null) => void
  showAll: boolean
  setShowAll: (value: boolean) => void
  refreshFunction: () => void
  stuffToPush: boolean
  pushFunction: () => void
  openNewPriceModal: (instrumentId: string | null, prefillPriceStr: string, prefillDeltaStr: string) => void
  openMassManualModal: () => void
}): React.JSX.Element {
  const {
    currentTime,
    setTime,
    showAll,
    setShowAll,
    refreshFunction,
    stuffToPush,
    pushFunction,
    openNewPriceModal,
    openMassManualModal
  } = props
  const { palette } = useTheme()

  const styles = {
    dateTimePickerContainer: {
      height: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        height: '100%',
        width: '100%'
      },
      '& .MuiInputBase-root': {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }
    }
  }

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={6} sx={{ height: '100%' }}>
        <Stack direction={'row'} alignItems={'center'} spacing={3} sx={{ height: '100%', width: '100%' }}>
          <Box sx={styles.dateTimePickerContainer}>
            <DateTimePicker
              value={currentTime}
              format='YYYY-MM-DD HH:mm'
              onChange={(time) => { setTime(time) }}
              closeOnSelect={false}
              ampm={false}
              timeSteps={{ hours: 1, minutes: 1 }}
              slotProps={{
                actionBar: {
                  actions: ['clear', 'accept']
                }
              }}
              onOpen={() => {
                if (currentTime === null) {
                  setTime(getLastMark())
                }
              }}
            />
          </Box>
          <FormGroup>
            <FormControlLabel
              control={<Switch
                sx={{
                  '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: palette.accent.main,
                    opacity: 0.9
                  },
                  '& .MuiSwitch-thumb': {
                    color: palette.primary.main
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: palette.tableBodyText.main
                  }
                }}
                color='default'
                checked={showAll}
                onChange={() => { setShowAll(!showAll) }}
              />}
              label='Include Auto Prices?'
              sx={{ color: palette.tableBodyText.main }}
            />
          </FormGroup>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'center'}
        sx={{ height: '100%' }}
      >
        <Grid
          item
          xs={6}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{ height: '100%' }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignContent={'center'}
            sx={{ height: '50%' }}
          >
            { stuffToPush && <PushButton
              pushFunction={pushFunction}
            /> }
            <IconButton
              onClick={() => { openNewPriceModal(null, '', '') }}
            >
              <AddIcon />
            </IconButton>
            <Button
              variant='contained'
              style={{
                width: '100%',
                backgroundColor: palette.accent.main,
                color: palette.primary.main
              }}
              onClick={openMassManualModal}
            >
              Update Manuals
            </Button>
            <RefreshButton
              refreshFunction={refreshFunction}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PricingControlsPanel
