import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { type Instrument, InstrumentCreator, glossaryApi } from '@r40cap/pms-sdk'

function AddInstrumentModal (): React.JSX.Element {
  const navigate = useNavigate()
  const { palette } = useTheme()
  const { showSnackbar } = useRequestSnackbar()
  const [postAdditions] = glossaryApi.useAddInstrumentsMutation()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/glossary/instruments')
    }
  }

  function addInstrument (instrument: Instrument): void {
    showSnackbar({
      isOpen: true,
      message: 'Creating Instrument',
      status: 'processing'
    })
    postAdditions({
      additions: [instrument]
    })
      .then((value) => {
        showSnackbar({
          isOpen: true,
          message: 'Created Instrument',
          status: 'success'
        })
        exitModal()
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to create Instrument',
          status: 'error'
        })
      })
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: '30vw',
          padding: '40px'
        }}
      >
        <InstrumentCreator
          submitFunction={addInstrument}
          mainColor={palette.accent.main}
          secondaryColor={palette.tableBodyText.main}
          callColor={palette.success.main}
          putColor={palette.error.main}
          verticalSpacing={2}
          horizontalSpacing={1}
          enhanced={true}
        />
      </Box>
    </Modal>
  )
}

export default AddInstrumentModal
