import React from 'react'
import {
  Box,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
  useTheme
} from '@mui/material'

import type { GlossaryOption } from './types'
import { allSelectOptions, primarySelectOptions } from './constants'

function GlossarySelection (props: {
  currentType: GlossaryOption
  typeChangeFunction: (type: GlossaryOption) => void
  showBaseTables: boolean
  setShowBaseTables: (value: boolean) => void
}): React.JSX.Element {
  const {
    currentType,
    typeChangeFunction,
    showBaseTables,
    setShowBaseTables
  } = props
  const { palette } = useTheme()

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const newType: GlossaryOption = event.target.value as GlossaryOption
    typeChangeFunction(newType)
  }

  return (
    <Box>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Select
          value={currentType}
          onChange={handleTypeChange}
          sx={{ width: '20vh' }}
          color="accent"
        >
          {
            showBaseTables
              ? allSelectOptions.map(
                (option, i) => {
                  return (
                    <MenuItem value={option.type} key={i}>
                      {option.display}
                    </MenuItem>
                  )
                }
              )
              : primarySelectOptions.map(
                (option, i) => {
                  return (
                    <MenuItem value={option.type} key={i}>
                      {option.display}
                    </MenuItem>
                  )
                }
              )
          }
        </Select>
        <FormGroup>
          <FormControlLabel
            control={<Switch
              sx={{
                '& .Mui-checked+.MuiSwitch-track': {
                  backgroundColor: palette.accent.main,
                  opacity: 0.9
                },
                '& .MuiSwitch-thumb': {
                  color: palette.primary.main
                },
                '& .MuiSwitch-track': {
                  backgroundColor: palette.tableBodyText.main
                }
              }}
              color='default'
              checked={showBaseTables}
              onChange={() => { setShowBaseTables(!showBaseTables) }}
            />}
            label='Show Base Tables?'
            sx={{ color: palette.tableBodyText.main }}
          />
        </FormGroup>
      </Stack>
    </Box>
  )
}

export default GlossarySelection
