import React from 'react'
import type { Dayjs } from 'dayjs'
import {
  Button,
  Box,
  FormGroup,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  useTheme
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { RefreshButton } from '@r40cap/ui'

import ReconciliationViewSelection from '../ReconciliationViewSelection'
import type { ReconciliationOption } from '../types'

function HistoryControlsPanel (props: {
  refreshFunction: () => void
  pushFunction: () => void
  showPushButton: boolean
  viewType: ReconciliationOption
  setViewType: (type: ReconciliationOption) => void
  time: Dayjs
  setTime: (value: Dayjs) => void
  useEntryTime: boolean
  setUseEntryTime: (value: boolean) => void
}): React.JSX.Element {
  const {
    refreshFunction,
    showPushButton,
    pushFunction,
    viewType,
    setViewType,
    time,
    setTime,
    useEntryTime,
    setUseEntryTime
  } = props
  const { palette } = useTheme()

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  return (
    <Grid container sx={{ height: '6vh' }}>
      <Grid item xs={10}>
        <Stack direction={'row'} alignItems={'center'} spacing={3}>
          <Box sx={{ height: '100%', width: '15%' }}>
            <ReconciliationViewSelection
              currentType={viewType}
              typeChangeFunction={setViewType}
            />
          </Box>
          <DateTimePicker
            label='Time'
            value={time}
            format='YYYY-MM-DD HH:mm'
            onChange={handleChangeTime}
            closeOnSelect={false}
            ampm={false}
            timeSteps={{ hours: 1, minutes: 1 }}
          />
          <FormGroup>
            <FormControlLabel
              control={<Switch
                sx={{
                  '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: palette.accent.main,
                    opacity: 0.9
                  },
                  '& .MuiSwitch-thumb': {
                    color: palette.primary.main
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: palette.tableBodyText.main
                  }
                }}
                color='default'
                checked={useEntryTime}
                onChange={() => { setUseEntryTime(!useEntryTime) }}
              />}
              label='Query by Entry?'
              sx={{ color: palette.tableBodyText.main }}
            />
          </FormGroup>
        </Stack>
      </Grid>
      <Grid
        item
        xs={2}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'center'}
      >
        <Grid
          item
          xs={6}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignContent={'center'}
            sx={{ height: '50%' }}
          >
            {
              showPushButton && <Button
                sx={{ color: palette.accent.main }}
                variant='outlined'
                onClick={pushFunction}
              >
                PUSH
              </Button>
            }
            <RefreshButton
              refreshFunction={refreshFunction}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HistoryControlsPanel
