import type { ColumnDefinition } from '@r40cap/ui'
import type { AssetChange, Expense, Liability, TransactionAssetChange, TransactionLiability } from '@r40cap/pms-sdk'

import type {
  AssetChangeRow,
  BlotterBigModal,
  ExistingLiabilityRow,
  LoanTransactionAdditionSelectOption
} from './types'
import {
  type AddAssetChangeRow,
  type AddLiabilityRow,
  type ExpenseRow,
  type LiabilityRow,
  BlotterInputType
} from '../common/types'

export const expenseRow: Array<ColumnDefinition<ExpenseRow, BlotterInputType, Expense>> = [
  {
    id: 'time',
    label: 'Time',
    columnWidthPercentage: 20,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'vendor',
    label: 'Vendor',
    columnWidthPercentage: 11,
    inputType: BlotterInputType.ENTITY,
    editId: 'vendor',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'reason',
    label: 'Reason',
    columnWidthPercentage: 8,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRING,
    editId: 'reason',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]

export const txnDetailsAssetChangeColumns: Array<ColumnDefinition<AssetChangeRow, BlotterInputType, AssetChange, string, BlotterBigModal>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 5,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'time',
    label: 'Time',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.TIME,
    editId: 'time',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'settledTime',
    label: 'Settled Time',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.TIME,
    editId: 'settledTime',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'isFee',
    label: 'Fee?',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    inputType: BlotterInputType.BOOLEAN,
    editId: 'isFee',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'expenseReason',
    label: 'Expense',
    columnWidthPercentage: 8,
    overflowType: 'tooltip',
    modalId: 'expenseId',
    modalType: 'expense',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'investor',
    label: 'Investor',
    columnWidthPercentage: 8,
    overflowType: 'tooltip',
    modalId: 'capitalCallId',
    modalType: 'capitalCall',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    }
  }
]

export const expenseDetailsAssetChangeColumns: Array<ColumnDefinition<AssetChangeRow, BlotterInputType, AssetChange, string, BlotterBigModal>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'time',
    label: 'Time',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    modalId: 'transactionId',
    modalType: 'transaction',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'isFee',
    label: 'Fee?',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    inputType: BlotterInputType.BOOLEAN,
    editId: 'isFee',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    }
  }
]

export const capitalCallDetailsAssetChangeColumns: Array<ColumnDefinition<AssetChangeRow, BlotterInputType, AssetChange, string, BlotterBigModal>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'time',
    label: 'Time',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    modalId: 'transactionId',
    modalType: 'transaction',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'isFee',
    label: 'Fee?',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    inputType: BlotterInputType.BOOLEAN,
    editId: 'isFee',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    }
  }
]

export const transactionAssetChangeColumns: Array<ColumnDefinition<AddAssetChangeRow, BlotterInputType, TransactionAssetChange>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 7,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 14,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'time',
    label: 'Time',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: BlotterInputType.TIME,
    editId: 'time',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'settledTime',
    label: 'Settled Time',
    columnWidthPercentage: 9,
    overflowType: 'scroll',
    inputType: BlotterInputType.TIME,
    editId: 'settledTime',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 12,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'isFee',
    label: 'Fee?',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    inputType: BlotterInputType.BOOLEAN,
    editId: 'isFee',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    }
  }
]

export const openedLiabilityColumns: Array<ColumnDefinition<LiabilityRow, BlotterInputType, Liability, string, BlotterBigModal>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'counterparty',
    label: 'Counterparty',
    columnWidthPercentage: 10,
    modalType: 'loan',
    modalId: 'loanId',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'openTime',
    label: 'Opened',
    columnWidthPercentage: 12,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'closeTime',
    label: 'Closed',
    columnWidthPercentage: 12,
    overflowType: 'scroll',
    modalId: 'closeTransactionId',
    modalType: 'transaction',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    reverseColor: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'interestRate',
    label: 'Rate',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'interestRate',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'topUpLevel',
    label: 'Top Up',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'topUpLevel',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]

export const loanDetailsLiabilityColumns: Array<ColumnDefinition<LiabilityRow, BlotterInputType, Liability, string, BlotterBigModal>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'openTime',
    label: 'Opened',
    columnWidthPercentage: 12,
    overflowType: 'scroll',
    modalId: 'openTransactionId',
    modalType: 'transaction',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'closeTime',
    label: 'Closed',
    columnWidthPercentage: 12,
    overflowType: 'scroll',
    modalId: 'closeTransactionId',
    modalType: 'transaction',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    reverseColor: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'interestRate',
    label: 'Rate',
    columnWidthPercentage: 7,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'interestRate',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'topUpLevel',
    label: 'Top Up',
    columnWidthPercentage: 7,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'topUpLevel',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]

export const closedLiabilityColumns: Array<ColumnDefinition<LiabilityRow, BlotterInputType, Liability, string, BlotterBigModal>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'counterparty',
    label: 'Counterparty',
    columnWidthPercentage: 10,
    modalType: 'loan',
    modalId: 'loanId',
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'openTime',
    label: 'Opened',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    modalId: 'openTransactionId',
    modalType: 'transaction',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'closeTime',
    label: 'Closed',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    reverseColor: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'interestRate',
    label: 'Rate',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'interestRate',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'topUpLevel',
    label: 'Top Up',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'topUpLevel',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]

export const transactionLiabilityColumns: Array<ColumnDefinition<AddLiabilityRow, BlotterInputType, TransactionLiability>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    reverseColor: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'interestRate',
    label: 'Rate',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'interestRate',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'topUpLevel',
    label: 'Top Up',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'topUpLevel',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]

export const existingLiabilityColumns: Array<ColumnDefinition<ExistingLiabilityRow, BlotterInputType, Liability>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'openTime',
    label: 'Opened',
    columnWidthPercentage: 8,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    reverseColor: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'interestRate',
    label: 'Rate',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'interestRate',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimals: 3
  },
  {
    id: 'topUpLevel',
    label: 'Top Up',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'topUpLevel',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimals: 3
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  }
]

export const loanTransactionSelectOptions: LoanTransactionAdditionSelectOption[] = [
  {
    type: 'basicLoan',
    display: 'Basic Loan',
    width: 90
  },
  {
    type: 'custom',
    display: 'Custom',
    width: 90
  }
]

export const assetChangeColumns: Array<ColumnDefinition<AssetChangeRow, BlotterInputType, AssetChange, string, BlotterBigModal>> = [
  {
    id: 'desk',
    label: 'Desk',
    columnWidthPercentage: 5,
    overflowType: 'scroll',
    inputType: BlotterInputType.DESK,
    editId: 'desk',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'strategy',
    label: 'Strategy',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.STRATEGY,
    editId: 'strategy',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'time',
    label: 'Time',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    modalId: 'time',
    modalType: 'transaction',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'quantity',
    label: 'Amount',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'quantity',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  },
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.INSTRUMENT,
    editId: 'instrument',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.NUMBER,
    editId: 'price',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: BlotterInputType.ACCOUNT,
    editId: 'account',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'isFee',
    label: 'Fee?',
    columnWidthPercentage: 4,
    overflowType: 'wrap',
    inputType: BlotterInputType.BOOLEAN,
    editId: 'isFee',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'center',
      prefix: null,
      suffix: null
    }
  }
]
