import React from 'react'
import { Box, Grid, Stack, useTheme } from '@mui/material'
import { RefreshButton } from '@r40cap/ui'
import { AccountInput, type LiteAccount } from '@r40cap/pms-sdk'

function RiskControlsPanel (props: {
  refreshFunction: () => void
  setAccount: (account: LiteAccount | null) => void
  accountId?: string
}): React.JSX.Element {
  const {
    refreshFunction,
    setAccount,
    accountId
  } = props
  const { palette } = useTheme()

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={10} sx={{ height: '100%' }}>
        <Stack direction={'row'} alignItems={'center'} spacing={3} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ width: '40%', height: '100%' }}>
            <AccountInput
              setAccount={setAccount}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              enhanced={false}
              prefillAccountId={accountId}
              includeEntityFilter
            />
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={2}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'center'}
        sx={{ height: '100%' }}
      >
        <Grid
          item
          xs={6}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{ height: '100%' }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignContent={'center'}
            sx={{ height: '50%' }}
          >
            <RefreshButton
              refreshFunction={refreshFunction}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RiskControlsPanel
