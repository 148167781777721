import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Tooltip, useTheme } from '@mui/material'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'

import { getMode, themeToggle } from './themeSlice'

export function ThemeToggle (props: {
  disabled: boolean
}): React.JSX.Element {
  const { disabled } = props
  const mode = useSelector(getMode)
  const dispatch = useDispatch()
  const { palette } = useTheme()
  const iconColor = palette.themeToggleIcon.main
  const isLightMode = mode === 'light'

  function toggleTheme (): void {
    dispatch(themeToggle())
  }

  const button = (
    <Button
      variant={isLightMode ? 'contained' : 'outlined'}
      onClick={() => {
        if (!disabled) {
          toggleTheme()
        }
      }}
      color="themeToggleBackground"
    >
      {isLightMode
        ? (
            <DarkModeIcon sx={{ color: iconColor }} />
          )
        : (
            <LightModeIcon sx={{ color: iconColor }} />
          )}
    </Button>
  )

  return (
    <div>
      {
        disabled
          ? <Tooltip
            title={'Soon'} arrow
          >
            {button}
          </Tooltip>
          : button
      }
    </div>
  )
}
