import React, { useState } from 'react'
import {
  useNavigate,
  useParams
} from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { useAuth } from '@r40cap/auth'

import NoAccess from './NoAccess'
import Page from './Page'
import RiskControlsPanel from '../components/risk/RiskControlsPanel'
import RiskReturnDisplay from '../components/risk/RiskReturnDisplay'
import NoAccountChosen from '../components/utils/NoAccountChosen'

function Risk (): React.JSX.Element {
  const navigate = useNavigate()
  const authContext = useAuth()
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const { accountId } = useParams()

  return (
    authContext.hasRiskAccess as boolean
      ? <Page>
          <Stack
            spacing={1}
            direction={'column'}
            width={'80%'}
            sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
          >
            <Box sx={{ height: '7%' }}>
              <RiskControlsPanel
                refreshFunction={() => { setRefreshSignal(!(refreshSignal as boolean)) }}
                setAccount={(account) => {
                  account === null
                    ? navigate('/risk')
                    : navigate(`/risk/${account.id}`)
                }}
                accountId={accountId}
              />
            </Box>
            <Box sx={{ height: '93%' }}>
              {
                accountId === undefined
                  ? <NoAccountChosen />
                  : <RiskReturnDisplay refreshSignal={refreshSignal} />
              }
            </Box>
          </Stack>
        </Page>
      : <NoAccess />
  )
}

export default Risk
