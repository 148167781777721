import React from 'react'

import Page from './Page'

function NoAccess (): React.JSX.Element {
  return (
    <Page>
      <h1>No Access</h1>
    </Page>
  )
}

export default NoAccess
