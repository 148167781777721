import React from 'react'
import { MenuItem, Select, type SelectChangeEvent } from '@mui/material'

import type { ReconciliationOption } from './types'
import { selectOptions } from './constants'

function ReconciliationViewSelection (props: {
  currentType: ReconciliationOption
  typeChangeFunction: (type: ReconciliationOption) => void
}): React.JSX.Element {
  const {
    currentType,
    typeChangeFunction
  } = props

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const newType: ReconciliationOption = event.target.value as ReconciliationOption
    typeChangeFunction(newType)
  }

  return (
    <Select
      value={currentType}
      onChange={handleTypeChange}
      sx={{ width: '100%', height: '100%' }}
      color="accent"
    >
      {
        selectOptions.map(
          (option, i) => {
            return (
              <MenuItem value={option.type} key={i}>
                {option.display}
              </MenuItem>
            )
          }
        )
      }
    </Select>
  )
}

export default ReconciliationViewSelection
