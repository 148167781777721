import React from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { useAuth } from '@r40cap/auth'

import NoAccess from './NoAccess'
import Page from './Page'
import UpdatingView from '../components/reconciliation/UpdatingView'
import HistoryView from '../components/reconciliation/HistoryView'

// TODO: Figure out how to make this cleaner (with Controls)

function Reconciliation (): React.JSX.Element {
  const authContext = useAuth()
  const navigate = useNavigate()

  return (
    authContext.hasReconciliationAccess as boolean
      ? <Page>
          <Routes>
            <Route path={''} element={<Navigate to={'recon'} />} />
            <Route
              path={'recon'}
              element={<UpdatingView
                viewType={'recon'}
                setViewType={(newView) => { navigate(`/reconciliation/${newView}`) }}
              />}
            />
            <Route
              path={'logs'}
              element={<HistoryView
                viewType={'logs'}
                setViewType={(newView) => { navigate(`/reconciliation/${newView}`) }}
              />}
            />
            <Route path={'*'} element={<Navigate to={'recon'} />} />
          </Routes>
        </Page>
      : <NoAccess />
  )
}

export default Reconciliation
