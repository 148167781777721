import React, { useEffect, useState } from 'react'
import { useRequestSnackbar } from '@r40cap/ui'
import { type PricingMethod, glossaryApi } from '@r40cap/pms-sdk'

import GlossaryTable from './GlossaryTable'
import { pricingMethodColumns } from './constants'
import type { TableProps } from './types'

function PricingMethodTable (props: TableProps): React.JSX.Element {
  const {
    refreshSignal,
    pushSignal,
    includeDeprecated,
    editedList,
    markEdited,
    clearEdited
  } = props
  const { showSnackbar } = useRequestSnackbar()
  const [editedData, setEditedData] = useState<PricingMethod[]>([])
  const [clearSelectedSignal, setClearSelectedSignal] = useState<boolean>(false)
  const [postEditsMutation] = glossaryApi.useEditPricingMethodsMutation()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [queryParams, setQueryParams] = useState({
    getAll: includeDeprecated,
    force: false
  })

  const { data, refetch, isFetching } = glossaryApi.useGetPricingMethodsQuery(queryParams, {
    skip: isInitialLoad
  })

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isInitialLoad])

  useEffect(() => {
    setQueryParams({
      getAll: includeDeprecated,
      force: false
    })
  }, [includeDeprecated])

  const handleRefresh = (): void => {
    if (!isInitialLoad) {
      if (!queryParams.force) {
        setQueryParams((prevParams) => ({
          ...prevParams,
          force: true
        }))
      } else {
        refetch().then(() => {
          clear()
        }).catch(() => {
          console.error('Error Refreshing')
        })
      }
    }
  }

  const handleEdit = (property: keyof PricingMethod, value: any, selected: readonly string[]): void => {
    const updatedData = editedData.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    setEditedData(updatedData)
    markEdited(selected)
  }

  const getPreset = (rowId: string, property: keyof PricingMethod): any => {
    const relevantObject = editedData.find((dataRow: PricingMethod) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setEditedData(data.data)
    }
  }, [data])

  useEffect(() => {
    const editedEditObjects: PricingMethod[] = editedData.filter((data) => editedList.includes(data.id))
    if (editedEditObjects.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Pushing Pricing Method Edits',
        status: 'processing'
      })
      postEditsMutation({ edits: editedEditObjects })
        .then((value) => {
          showSnackbar({
            isOpen: true,
            message: 'Pricing Method Edits Pushed',
            status: 'success'
          })
          clearEdited()
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }, [pushSignal])

  useEffect(() => {
    handleRefresh()
  }, [refreshSignal])

  function clear (): void {
    setClearSelectedSignal(!clearSelectedSignal)
  }

  return (
    <GlossaryTable<PricingMethod, PricingMethod>
      rows={editedData}
      columns={pricingMethodColumns}
      getPreset={getPreset}
      defaultOrderBy='name'
      isFetching={isFetching}
      editSubmission={handleEdit}
      clearSelectedSignal={clearSelectedSignal}
    />
  )
}

export default PricingMethodTable
