import React from 'react'
import { useTheme } from '@mui/material'
import { AuthGateway } from '@r40cap/auth'

import Page from './Page'

function Login (): React.JSX.Element {
  const { palette } = useTheme()

  return (
    <Page>
      <AuthGateway
        inputColor={palette.accent.main}
        logoColor={palette.logoColor.main}
        qrCodeBackgroundColor={palette.background.paper}
        qrCodeForegroundColor={palette.tableHeaderText.main}
      />
    </Page>
  )
}

export default Login
