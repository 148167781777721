import type { ColumnDefinition, DisplayDetails } from '@r40cap/ui'
import type {
  Account,
  BaseFx,
  Desk,
  Entity,
  Instrument,
  InstrumentType,
  Investor,
  PricingMethod,
  Strategy,
  SuperStrategy,
  Trader
} from '@r40cap/pms-sdk'

import {
  type AccountRow,
  GlossaryInputType,
  type InstrumentRow,
  type StrategyRow,
  type SuperStrategyRow
} from './types'

const basicTextColumnDetails: DisplayDetails = {
  alignment: 'left',
  prefix: null,
  suffix: null
}

const basicBooleanColumnDetails: DisplayDetails = {
  alignment: 'center',
  prefix: null,
  suffix: null
}

const basicNumberColumnDetails: DisplayDetails = {
  alignment: 'center',
  prefix: null,
  suffix: null
}

export const deskColumns: Array<ColumnDefinition<Desk, GlossaryInputType, Desk>> = [
  {
    id: 'name',
    label: 'Name',
    columnWidthPercentage: 90,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'name',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]

export const superStrategyColumns: Array<ColumnDefinition<SuperStrategyRow, GlossaryInputType, SuperStrategy>> = [
  {
    id: 'name',
    label: 'Name',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'name',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'description',
    label: 'Description',
    columnWidthPercentage: 70,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.TEXT,
    editId: 'description',
    sortable: false,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]

export const strategyColumns: Array<ColumnDefinition<StrategyRow, GlossaryInputType, Strategy>> = [
  {
    id: 'priorityLevel',
    label: 'Priority',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    inputType: GlossaryInputType.INTEGER,
    editId: 'priorityLevel',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'color',
    label: 'Color',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    inputType: GlossaryInputType.COLOR,
    editId: 'color',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'name',
    label: 'Name',
    columnWidthPercentage: 30,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'name',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'superStrategy',
    label: 'Super Strategy',
    columnWidthPercentage: 25,
    overflowType: 'wrap',
    inputType: GlossaryInputType.SUPER_STRATEGY,
    editId: 'superStrategy',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'description',
    label: 'Description',
    columnWidthPercentage: 32,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.TEXT,
    editId: 'description',
    sortable: false,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]

export const entityColumns: Array<ColumnDefinition<Entity, GlossaryInputType, Entity>> = [
  {
    id: 'name',
    label: 'Name',
    columnWidthPercentage: 90,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'name',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]

export const investorColumns: Array<ColumnDefinition<Investor, GlossaryInputType, Investor>> = [
  {
    id: 'name',
    label: 'Name',
    columnWidthPercentage: 90,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'name',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]

export const accountColumns: Array<ColumnDefinition<AccountRow, GlossaryInputType, Account>> = [
  {
    id: 'platform',
    label: 'Platform',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.ENTITY,
    editId: 'name',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'name',
    label: 'Name',
    columnWidthPercentage: 25,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'name',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'associatedDesk',
    label: 'Desk',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.DESK,
    editId: 'associatedDesk',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'platformApiId',
    label: 'API ID',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.TEXT,
    editId: 'platformApiId',
    sortable: false,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'platformSecretName',
    label: 'Secret Name',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.TEXT,
    editId: 'platformSecretName',
    sortable: false,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'isSelfCustody',
    label: 'Self Custody?',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isSelfCustody',
    sortable: true,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'isComingled',
    label: 'Comingled?',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isComingled',
    sortable: true,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'isExternallyVisible',
    label: 'Externally Visible?',
    columnWidthPercentage: 7,
    overflowType: 'wrap',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isExternallyVisible',
    sortable: true,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 7,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]

export const baseFxColumns: Array<ColumnDefinition<BaseFx, GlossaryInputType, BaseFx>> = [
  {
    id: 'ticker',
    label: 'Ticker',
    columnWidthPercentage: 90,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'ticker',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]

export const instrumentTypeColumns: Array<ColumnDefinition<InstrumentType, GlossaryInputType, InstrumentType>> = [
  {
    id: 'name',
    label: 'Name',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'name',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'hasExpiration',
    label: 'Has Expiration?',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'hasExpiration',
    sortable: true,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'hasStrikePrice',
    label: 'Has Strike?',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'hasStrikePrice',
    sortable: true,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'hasCallPut',
    label: 'Call/Put?',
    columnWidthPercentage: 7,
    overflowType: 'wrap',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'hasCallPut',
    sortable: true,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'hasCounterparty',
    label: 'Has Counterparty?',
    columnWidthPercentage: 9,
    overflowType: 'wrap',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'hasCounterparty',
    sortable: true,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'hasSettlementInstrument',
    label: 'Has Settlement?',
    columnWidthPercentage: 7,
    overflowType: 'wrap',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'hasSettlementInstrument',
    sortable: true,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 6,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]

export const pricingMethodColumns: Array<ColumnDefinition<PricingMethod, GlossaryInputType, PricingMethod>> = [
  {
    id: 'name',
    label: 'Name',
    columnWidthPercentage: 80,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'name',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'requiresId',
    label: 'Requires ID?',
    columnWidthPercentage: 10,
    overflowType: 'wrap',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'requiresId',
    sortable: true,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]

export const instrumentColumns: Array<ColumnDefinition<InstrumentRow, GlossaryInputType, Instrument>> = [
  {
    id: 'displayTicker',
    label: 'Ticker',
    columnWidthPercentage: 8,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'displayTicker',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'isCash',
    label: 'Cash',
    columnWidthPercentage: 4,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isCash',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'baseFx',
    label: 'Base FX',
    columnWidthPercentage: 8,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.BASE_FX,
    editId: 'baseFx',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'counterparty',
    label: 'Counterparty',
    columnWidthPercentage: 8,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.COUNTERPARTY,
    editId: 'counterparty',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'settlementInstrument',
    label: 'Settlement',
    columnWidthPercentage: 8,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.SETTLEMENT_INSTRUMENT,
    editId: 'settlementInstrument',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'multiplier',
    label: 'Mult.',
    columnWidthPercentage: 4,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.NUMBER,
    editId: 'multiplier',
    sortable: false,
    valueDetails: basicNumberColumnDetails,
    headDetails: basicNumberColumnDetails,
    colored: false
  },
  {
    id: 'instrumentType',
    label: 'Type',
    columnWidthPercentage: 8,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.INSTRUMENT_TYPE,
    editId: 'instrumentType',
    sortable: false,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'expiration',
    label: 'Expiration',
    columnWidthPercentage: 8,
    overflowType: 'scroll',
    inputType: GlossaryInputType.DATETIME,
    editId: 'expiration',
    sortable: false,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'strikePrice',
    label: 'Strike',
    columnWidthPercentage: 8,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.NUMBER,
    editId: 'strikePrice',
    sortable: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: basicNumberColumnDetails,
    colored: false
  },
  {
    id: 'isCall',
    label: 'Call',
    columnWidthPercentage: 4,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isCall',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  },
  {
    id: 'pricingMethod',
    label: 'Pricing Method',
    columnWidthPercentage: 7,
    overflowType: 'scroll',
    inputType: GlossaryInputType.PRICING_METHOD,
    editId: 'pricingMethod',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'methodId',
    label: 'Pricing ID',
    columnWidthPercentage: 7,
    overflowType: 'scroll',
    inputType: GlossaryInputType.TEXT,
    editId: 'methodId',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'quantityDecimals',
    label: 'Q Decimals',
    columnWidthPercentage: 7,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.INTEGER,
    editId: 'quantityDecimals',
    sortable: false,
    valueDetails: basicNumberColumnDetails,
    headDetails: basicNumberColumnDetails,
    colored: false
  },
  {
    id: 'priceDecimals',
    label: 'P Decimals',
    columnWidthPercentage: 7,
    overflowType: 'tooltip',
    inputType: GlossaryInputType.INTEGER,
    editId: 'priceDecimals',
    sortable: false,
    valueDetails: basicNumberColumnDetails,
    headDetails: basicNumberColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 7,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]

export const traderColumns: Array<ColumnDefinition<Trader, GlossaryInputType, Trader>> = [
  {
    id: 'firstName',
    label: 'First',
    columnWidthPercentage: 45,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'firstName',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'lastName',
    label: 'Last',
    columnWidthPercentage: 45,
    overflowType: 'wrap',
    inputType: GlossaryInputType.TEXT,
    editId: 'lastName',
    sortable: true,
    valueDetails: basicTextColumnDetails,
    headDetails: basicTextColumnDetails,
    colored: false
  },
  {
    id: 'isDeprecated',
    label: 'Deprecated',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    inputType: GlossaryInputType.BOOLEAN,
    editId: 'isDeprecated',
    sortable: false,
    valueDetails: basicBooleanColumnDetails,
    headDetails: basicBooleanColumnDetails,
    colored: false
  }
]
