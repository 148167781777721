import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress, useTheme, Stack } from '@mui/material'
import { riskApi } from '@r40cap/pms-sdk'
import RiskComponentDisplay from './RiskComponentDisplay'

function RiskReturnDisplay (props: {
  refreshSignal: boolean
}): React.JSX.Element {
  const {
    refreshSignal
  } = props
  const { accountId } = useParams()
  const { palette } = useTheme()

  const { data, isFetching, refetch } = riskApi.useGetPlatformRiskQuery({
    accountId: accountId ?? ''
  })

  useEffect(() => {
    refetch().catch(() => {
      console.error('Error Refreshing')
    })
  }, [refreshSignal])

  return (
    <Box sx={{
      backgroundColor: palette.primary.main,
      height: '100%',
      borderRadius: '5px',
      overflow: 'scroll'
    }}>
      {
        (isFetching as boolean || data === undefined || data === null)
          ? <Box sx={{ alignContent: 'center', alignSelf: 'center', height: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress sx={{ color: palette.accent.main }}/>
              </div>
            </Box>
          : <Stack direction={'column'} padding={1} spacing={1}>
              {data.data.components.map((comp, idx) => <RiskComponentDisplay key={idx} component={comp} />)}
            </Stack>
      }
    </Box>
  )
}

export default RiskReturnDisplay
