export interface UpdatingRow {
  instrumentId: string
  instrument: string
  ourBalance: number
  platformBalance: number
  platformErrorExplanation: string
  adminBalance: number
  adminErrorExplanation: string
  balanceDecimals: number
}

export interface UnmatchedRow {
  ticker: string
  platformBalance: number
}

export enum UpdatingInputType {
  NUMBER,
  TEXT
}
