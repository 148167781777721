import React from 'react'
import { CircularProgress, useTheme } from '@mui/material'

import Page from './Page'

function Loading (): React.JSX.Element {
  const { palette } = useTheme()

  return (
    <Page>
      <CircularProgress sx={{ color: palette.accent.main }}/>
    </Page>
  )
}

export default Loading
