import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import AddIcon from '@mui/icons-material/Add'
import { EditableTable, ModalOpenButton } from '@r40cap/ui'
import {
  type LiteAccount,
  AccountInput,
  type LiteInstrument,
  type TransactionAssetChange,
  TransactionAssetChangeCreator,
  type TransactionCreation,
  type CapitalCall,
  type LiteDesk,
  DeskInput,
  type LiteStrategy,
  StrategyInput
} from '@r40cap/pms-sdk'

import type { CapitalCallAdditionSelectOption, BlotterInputType, AddAssetChangeRow } from '../../common/types'
import { transactionAssetChangeColumns } from '../constants'
import { getBlotterEditModalContent } from '../../utils'
import { capitalCallSelectOptions } from '../../common/constants'
import LegInput from '../../common/BlotterInputs/LegInput'

function Custom (props: {
  submit: (transaction: TransactionCreation) => void
  capitalCall: CapitalCall
}): React.JSX.Element {
  const { submit, capitalCall } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [assetChanges, setAssetChanges] = useState<readonly TransactionAssetChange[]>([])

  const [assetChangeAddModalOpen, setAssetChangeAddModalOpen] = useState<boolean>(false)
  const [selectedAssetChangeIds, setSelectedAssetChangeIds] = useState<readonly string[]>([])

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function appendAssetChange (assetChange: TransactionAssetChange): void {
    setAssetChanges(assetChanges.concat({
      ...assetChange,
      associatedCapitalCall: capitalCall.id
    }))
    setAssetChangeAddModalOpen(false)
  }

  const getAssetChangePreset = (rowId: string, property: keyof TransactionAssetChange): any => {
    const relevantObject = assetChanges.find((dataRow: TransactionAssetChange) => dataRow.id === rowId)
    if (relevantObject === undefined) {
      return undefined
    } else {
      return relevantObject[property]
    }
  }

  const handleAssetChangeEdit = (property: keyof TransactionAssetChange, value: any, selected: readonly string[]): void => {
    const updatedData = assetChanges.map(item =>
      selected.includes(item.id) ? { ...item, [property]: value } : item
    )
    setAssetChanges(updatedData)
  }

  function handleSubmission (): void {
    if (
      time !== null
    ) {
      const txnId = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: undefined,
        expectedSettlementTime: undefined,
        comments: `${capitalCall.investor.name} Capital Call: ${capitalCall.memo}`,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges: assetChanges.map((value) => value),
        openedLiabilities: [],
        closedLiabilities: []
      }
      submit(txn)
    }
  }

  return (
    <Stack direction={'column'} spacing={1}>
      <Stack direction={'row'} sx={{ width: '100%' }}>
        <Box sx={{ width: '50%' }}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography>Time</Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Box sx={{ width: '100%' }} textAlign={'right'}>
        <ModalOpenButton
          modalOpen={assetChangeAddModalOpen}
          setModalOpen={setAssetChangeAddModalOpen}
          modalContents={<Box
            sx={{
              width: '40vw',
              padding: '40px'
            }}
          >
            <TransactionAssetChangeCreator
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              callColor={palette.success.main}
              putColor={palette.error.main}
              submitFunction={appendAssetChange}
              includeBaseFxFilter
              includeEntityFilter
              includeInstrumentTypeFilter
              includeSuperStrategyFilter
              enhanced
            />
          </Box>}
        >
          <AddIcon />
        </ModalOpenButton>
      </Box>
      <Box
        sx={{ height: '40vh' }}
      >
        <EditableTable<AddAssetChangeRow, TransactionAssetChange, BlotterInputType, string>
          rows={assetChanges.map((assetChange) => {
            return {
              id: assetChange.id,
              time: assetChange.time,
              settledTime: assetChange.settledTime ?? '',
              account: `${assetChange.account.platform.name} - ${assetChange.account.name}`,
              strategy: (
                assetChange.strategy === undefined || assetChange.strategy === null
                  ? ''
                  : `${assetChange.strategy.superStrategy.name} - ${assetChange.strategy.name}`
              ),
              desk: assetChange.desk?.name ?? '',
              instrument: assetChange.instrument.displayTicker,
              quantity: assetChange.quantity,
              price: assetChange.price,
              isFee: assetChange.isFee,
              quantityDecimals: assetChange.instrument.quantityDecimals,
              priceDecimals: assetChange.instrument.priceDecimals
            }
          })}
          columns={transactionAssetChangeColumns}
          selected={selectedAssetChangeIds}
          setSelected={setSelectedAssetChangeIds}
          defaultOrderBy={'instrument'}
          defaultOrder={'desc'}
          isFetching={false}
          getPreset={getAssetChangePreset}
          getModalContent={(
            inputType: BlotterInputType,
            label: string,
            editProperty: keyof TransactionAssetChange,
            closeModal: () => void,
            newSelected: readonly string[],
            initialValue: any
          ) => {
            return getBlotterEditModalContent<TransactionAssetChange>(
              inputType,
              label,
              editProperty,
              closeModal,
              handleAssetChangeEdit,
              newSelected,
              initialValue
            )
          }}
          checkboxColor={palette.accent.main}
          backgroundColor={palette.primary.main}
          headerBackgroundColor={palette.background.default}
          headerTextColor={palette.tableHeaderText.main}
          headerActiveTextColor={palette.accent.main}
          bodyDefaultTextColor={palette.tableBodyText.main}
          bodyPrimaryBackgroundColor={palette.tertiary.main}
          redTextColor='red'
          greenTextColor='green'
          dense
        />
      </Box>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

function BasicCapitalCall (props: {
  submit: (transaction: TransactionCreation) => void
  capitalCall: CapitalCall
}): React.JSX.Element {
  const { submit, capitalCall } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [account, setAccount] = useState<LiteAccount | null>(null)
  const [instrument, setInstrument] = useState<LiteInstrument | null>(null)
  const [desk, setDesk] = useState<LiteDesk | null>(null)
  const [strategy, setStrategy] = useState<LiteStrategy | null>(null)
  const [amountStr, setAmountStr] = useState<string>('')
  const [priceStr, setPriceStr] = useState<string>('')

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function handleSubmission (): void {
    if (
      time !== null &&
      instrument !== null &&
      account !== null &&
      amountStr !== ''
    ) {
      const txnId = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: undefined,
        expectedSettlementTime: undefined,
        comments: `${capitalCall.investor.name} Capital Call: ${capitalCall.memo}`,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges: [
          {
            id: `new-${txnId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument,
            quantity: Math.abs(parseFloat(amountStr)),
            price: Math.abs(parseFloat(priceStr)),
            isFee: false,
            associatedCapitalCall: capitalCall.id
          }
        ],
        openedLiabilities: [],
        closedLiabilities: []
      }
      submit(txn)
    }
  }

  return (
    <Stack spacing={1}>
      <Grid spacing={1} container sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography>Time</Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <AccountInput
            setAccount={setAccount}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            includeEntityFilter
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <StrategyInput
            setStrategy={setStrategy}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            includeSuperStrategyFilter
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <DeskInput
            setDesk={setDesk}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Called'
            amountStr={amountStr}
            setAmountStr={setAmountStr}
            priceStr={priceStr}
            setPriceStr={setPriceStr}
            setInstrument={setInstrument}
          />
        </Grid>
      </Grid>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

export function AddTransactionToCapitalCallModal (props: {
  submit: (transaction: TransactionCreation) => void
  capitalCall: CapitalCall
}): React.JSX.Element {
  const { submit, capitalCall } = props
  const [viewOption, setViewOption] = useState<CapitalCallAdditionSelectOption | undefined>(capitalCallSelectOptions.at(0))

  let content: React.JSX.Element
  switch (viewOption?.type) {
    case 'basicCapitalCall':
      content = <BasicCapitalCall submit={submit} capitalCall={capitalCall}/>
      break
    default:
      content = <Custom submit={submit} capitalCall={capitalCall}/>
  }

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const newType = event.target.value
    const targetOption = capitalCallSelectOptions.find((option) => option.type === newType)
    setViewOption(targetOption)
  }

  return (
    <Box
      sx={{
        width: `${viewOption?.width ?? 90}vw`,
        maxHeight: '95vh',
        overflow: 'scroll'
      }}
    >
      <Stack direction={'column'} padding={3} spacing={1}>
        <Box textAlign={'left'} sx={{ width: '100%' }}>
          <Select
            value={viewOption?.type ?? 'basicLoan'}
            onChange={handleTypeChange}
            sx={{ width: '15vw' }}
            color="accent"
          >
            {
              capitalCallSelectOptions.map(
                (option, i) => {
                  return (
                    <MenuItem value={option.type} key={i}>
                      {option.display}
                    </MenuItem>
                  )
                }
              )
            }
          </Select>
        </Box>
        {content}
      </Stack>
    </Box>
  )
}
