import React from 'react'

import { UpdatingInputType } from './types'
import { NumberEditContent, StringEditContent } from '../modals'

export function getEditModalContent (
  inputType: UpdatingInputType,
  label: string,
  closeModal: () => void,
  handleSubmission: (value: any) => void,
  currentValue: any
): React.JSX.Element {
  let content: React.JSX.Element = <></>
  switch (inputType) {
    case UpdatingInputType.TEXT:
      content = <StringEditContent
          title={label}
          closeModal={closeModal}
          submit={handleSubmission}
          prefillValue={currentValue}
        />
      break
    default:
      content = <NumberEditContent
          closeModal={closeModal}
          submit={handleSubmission}
          title={label}
          prefillValue={currentValue}
        />
  }
  return content
}
