export const darkPalette = {
  primary: {
    main: '#040404'
  },
  secondary: {
    main: '#1F1F1F'
  },
  tertiary: {
    main: '#161616'
  },
  background: {
    default: '#030303',
    paper: '#1F1F1F'
  },
  themeToggleBackground: {
    main: '#87CEEB'
  },
  themeToggleIcon: {
    main: '#FFCC33'
  },
  accent: {
    main: '#FFC000'
  },
  tableHeaderText: {
    main: '#F1FAFC'
  },
  tableBodyText: {
    main: '#F1FAFC'
  },
  logoColor: {
    main: '#EFEFEF'
  },
  staleColor: {
    main: '#F57D0C'
  }
}
