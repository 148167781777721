import React from 'react'
import { useAuth } from '@r40cap/auth'

import NoAccess from './NoAccess'
import Page from './Page'
import { PricingWrapper } from '../components/pricing'

function Portfolio (): React.JSX.Element {
  const authContext = useAuth()
  return (
    authContext.hasPricingAccess as boolean
      ? <Page>
          <PricingWrapper />
        </Page>
      : <NoAccess />
  )
}

export default Portfolio
