import React, { useEffect } from 'react'
import { type Dayjs } from 'dayjs'
import { reconciliationApi, portfolioApi, type PlatformSnapshot } from '@r40cap/pms-sdk'

import UpdatingViewTable from './UpdatingViewTable'
import type { UpdatingRow } from './types'

function SnapshotViewTable (props: {
  refreshSignal: boolean
  snapshot: PlatformSnapshot
  openModal: (content: React.JSX.Element) => void
  closeModal: () => void
  selectedIds: readonly string[]
  setSelectedIds: (value: string[]) => void
  setMap: (value: Map<string, UpdatingRow>) => void
  map: Map<string, UpdatingRow>
  systemTimeOverride: Dayjs | null
}): React.JSX.Element {
  const {
    snapshot,
    refreshSignal,
    openModal,
    closeModal,
    selectedIds,
    setSelectedIds,
    map,
    setMap,
    systemTimeOverride
  } = props
  const {
    data: platformData,
    refetch: platformRefetch,
    isFetching: platformIsFetching
  } = reconciliationApi.useGetSnapshotBalancesQuery({ snapshotId: snapshot.id })
  const {
    data: systemData,
    refetch: systemRefetch,
    isFetching: systemIsFetching
  } = portfolioApi.useGetAccountBalancesQuery({
    accountId: snapshot.account.id,
    time: systemTimeOverride !== null
      ? systemTimeOverride.format('YYYY-MM-DD HH:mm')
      : snapshot.snapshotTime,
    includeUnsettled: true
  })

  useEffect(() => {
    platformRefetch().catch(() => {
      console.error('Error Refreshing')
    })
    systemRefetch().catch(() => {
      console.error('Error Refreshing')
    })
  }, [refreshSignal])

  return (
    <UpdatingViewTable
      refreshSignal={refreshSignal}
      platformData={platformData?.data ?? null}
      platformIsFetching={platformIsFetching}
      systemData={systemData?.data ?? null}
      systemIsFetching={systemIsFetching}
      openModal={openModal}
      closeModal={closeModal}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      map={map}
      setMap={setMap}
    />
  )
}

export default SnapshotViewTable
