import React from 'react'
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import { InstrumentInput, type LiteInstrument } from '@r40cap/pms-sdk'
import { NumberInput } from '@r40cap/ui'

export default function RatedLegInput (props: {
  legName: string
  amountStr: string
  setAmountStr: (value: string) => void
  priceStr: string
  setPriceStr: (value: string) => void
  interestRateStr: string
  setInterestRateStr: (value: string) => void
  topUpLevelStr: string
  setTopUpLevelStr: (value: string) => void
  setInstrument: (value: LiteInstrument | null) => void
}): React.JSX.Element {
  const {
    legName,
    amountStr,
    setAmountStr,
    priceStr,
    setPriceStr,
    setInstrument,
    interestRateStr,
    setInterestRateStr,
    topUpLevelStr,
    setTopUpLevelStr
  } = props
  const { palette } = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: palette.primary.main,
        width: '100%',
        height: '100%',
        borderRadius: '5px'
      }}
    >
      <Stack spacing={1} padding={1}>
        <Typography color={palette.tableBodyText.main}>{legName}</Typography>
        <Grid spacing={1} container sx={{ width: '100%' }}>
          <Grid item xs={2}>
            <NumberInput
              title={`${legName} Amount`}
              intOnly={false}
              valueString={amountStr}
              setValueString={setAmountStr}
              color={palette.accent.main}
            />
          </Grid>
          <Grid item xs={2}>
            <NumberInput
              title={`${legName} Price`}
              intOnly={false}
              valueString={priceStr}
              setValueString={setPriceStr}
              color={palette.accent.main}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
              <NumberInput
                title={`${legName} Rate`}
                intOnly={false}
                valueString={interestRateStr}
                setValueString={setInterestRateStr}
                color={palette.accent.main}
              />
              <Typography color={palette.tableBodyText.main}>%</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
              <NumberInput
                title={`${legName} Top Up`}
                intOnly={false}
                valueString={topUpLevelStr}
                setValueString={setTopUpLevelStr}
                color={palette.accent.main}
              />
              <Typography color={palette.tableBodyText.main}>%</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <InstrumentInput
              setInstrument={setInstrument}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              callColor={palette.success.main}
              putColor={palette.error.main}
              enhanced
              includeBaseFxFilter
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  )
}
