import type { ReconciliationSelectOption } from './types'

export const selectOptions: ReconciliationSelectOption[] = [
  {
    type: 'recon',
    display: 'Recon'
  },
  {
    type: 'logs',
    display: 'Logs'
  }
]
