import { type ColumnDefinition } from '@r40cap/ui'

import { type PriceRow } from './types'

export const priceColumns: Array<ColumnDefinition<PriceRow, any>> = [
  {
    id: 'instrument',
    label: 'Instrument',
    columnWidthPercentage: 40,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'time',
    label: 'Last Update',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'price',
    label: 'Price',
    columnWidthPercentage: 14,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'priceDecimals'
  },
  {
    id: 'delta',
    label: 'Delta',
    columnWidthPercentage: 12,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]
