import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export function getLastMark (): Dayjs {
  const dayStart = getLastMarkDay()
  return dayStart.hour(16).minute(0).second(0).millisecond(0).utc()
}

const holidays = [
  '2024-01-01',
  '2024-01-15',
  '2024-02-19',
  '2024-03-29',
  '2024-05-27',
  '2024-06-19',
  '2024-07-04',
  '2024-09-02',
  '2024-11-28',
  '2024-12-25',
  '2024-12-31'
]

function getLastMarkDay (): Dayjs {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const easternTime = dayjs().tz('America/New_York')
  let currentDay = easternTime.hour() < 16
    ? easternTime.subtract(1, 'day').startOf('day')
    : easternTime.startOf('day')
  while (true) {
    const dayOfWeek = currentDay.day()
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0
    const dateStr = currentDay.format('YYYY-MM-DD')
    const isHoliday = holidays.includes(dateStr)
    if (!isWeekend && !isHoliday) {
      break
    }
    currentDay = currentDay.subtract(1, 'day')
  }
  return currentDay
}
