import React from 'react'
import { type Dayjs } from 'dayjs'
import moment from 'moment-timezone'
import {
  Grid,
  Box,
  type SelectChangeEvent,
  Stack,
  useTheme,
  MenuItem,
  Select,
  FormGroup,
  FormControlLabel,
  Typography,
  Switch
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { RefreshButton } from '@r40cap/ui'
import { useAuth } from '@r40cap/auth'

import { pnlTimingOptions } from './constants'
import { PnlTimeType, type PnlTimeOption } from './types'
import DeskSelection from '../common/DeskSelection'
import { type DeskOption } from '../common/types'

function PnlTimingSelction (props: {
  timing: PnlTimeOption
  timingChangeFunction: (option: PnlTimeOption) => void
}): React.JSX.Element {
  const { timing, timingChangeFunction } = props

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const chosenOption = pnlTimingOptions.find((option) => option.value === event.target.value)
    if (chosenOption !== undefined) {
      timingChangeFunction(chosenOption)
    }
  }

  return (
    <Select
      value={timing.value}
      onChange={handleTypeChange}
      sx={{ width: '100%', height: '100%' }}
      color="accent"
    >
      {pnlTimingOptions.map(
        (option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  )
}

function EODPicker (props: {
  time: Dayjs | null
  timeChangeFunction: (time: Dayjs | null) => void
}): React.JSX.Element {
  const { time, timeChangeFunction } = props

  const styles = {
    datePickerContainer: {
      height: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        height: '100%',
        width: '100%'
      },
      '& .MuiInputBase-root': {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }
    }
  }

  const handleChangeDate = (newDate: Dayjs | null): void => {
    if (newDate !== null) {
      const isDST = moment(newDate.toDate()).isDST()
      const easternOffset = isDST as boolean ? 4 : 5
      const easternTime = newDate.set('hour', 16)
      const utcTime = easternTime.add(easternOffset, 'hour')
      timeChangeFunction(utcTime)
    } else {
      timeChangeFunction(null)
    }
  }

  return (
    <Box sx={styles.datePickerContainer}>
      <DatePicker
        value={time}
        onChange={(newValue) => { handleChangeDate(newValue) }}
      />
    </Box>
  )
}

function PositionsSelctions (props: {
  time: Dayjs | null
  timeChangeFunction: (time: Dayjs | null) => void
  timing: PnlTimeOption
  timingChangeFunction: (option: PnlTimeOption) => void
  includeUnsettled: boolean
  includeUnsettledChangeFunction: (value: boolean) => void
}): React.JSX.Element {
  const {
    time,
    timeChangeFunction,
    timing,
    timingChangeFunction,
    includeUnsettled,
    includeUnsettledChangeFunction
  } = props
  const { palette } = useTheme()

  return <Stack
    direction={'row'}
    spacing={1}
    alignItems={'center'}
    sx={{ height: '100%', width: '100%' }}
  >
    <Box sx={{ width: '33%', height: '100%' }}>
      <PnlTimingSelction
        timing={timing}
        timingChangeFunction={timingChangeFunction}
      />
    </Box>
    {
      timing.type === PnlTimeType.EOD && <Box sx={{ width: '33%', height: '100%' }}>
        <EODPicker
          time={time}
          timeChangeFunction={timeChangeFunction}
        />
      </Box>
    }
    <FormGroup row>
      <FormControlLabel
        control={<Switch
          sx={{
            '& .Mui-checked+.MuiSwitch-track': {
              backgroundColor: palette.accent.main,
              opacity: 0.9
            },
            '& .MuiSwitch-thumb': {
              color: palette.primary.main
            },
            '& .MuiSwitch-track': {
              backgroundColor: palette.tableBodyText.main
            }
          }}
          color='default'
          checked={includeUnsettled}
          onChange={() => { includeUnsettledChangeFunction(!includeUnsettled) }}
        />}
        label={<Typography
          sx={{ color: palette.tableBodyText.main, fontSize: 12 }}
        >Include Unsettled?</Typography>}
        sx={{ color: palette.tableBodyText.main }}
      />
    </FormGroup>
  </Stack>
}

function PnlControlsPanel (props: {
  time: Dayjs | null
  timeChangeFunction: (time: Dayjs | null) => void
  pnlTiming: PnlTimeOption
  pnlTimingChangeFunction: (option: PnlTimeOption) => void
  includeUnsettled: boolean
  includeUnsettledChangeFunction: (value: boolean) => void
  refreshFunction: () => void
  desk: DeskOption
  setDesk: (value: DeskOption) => void
}): React.JSX.Element {
  const {
    time,
    timeChangeFunction,
    pnlTiming,
    pnlTimingChangeFunction,
    includeUnsettled,
    includeUnsettledChangeFunction,
    refreshFunction,
    desk,
    setDesk
  } = props
  const { palette } = useTheme()
  const authConext = useAuth()

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={9} sx={{ height: '100%' }}>
        <Stack direction={'row'} spacing={1} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ height: '100%', width: '50%' }}>
            <PositionsSelctions
              time={time}
              timeChangeFunction={timeChangeFunction}
              timing={pnlTiming}
              timingChangeFunction={pnlTimingChangeFunction}
              includeUnsettled={includeUnsettled}
              includeUnsettledChangeFunction={includeUnsettledChangeFunction}
            />
          </Box>
          <Box sx={{ height: '100%', width: '10%' }}>
          {
            authConext.restrictedDeskId === undefined && <DeskSelection
              currentDesk={desk}
              deskChange={setDesk}
            />
          }
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={3}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'center'}
        sx={{ height: '100%' }}
      >
        <Grid
          item
          xs={6}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{ height: '100%' }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignContent={'center'}
            sx={{ height: '50%' }}
          >
            <RefreshButton
              refreshFunction={refreshFunction}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PnlControlsPanel
